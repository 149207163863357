import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {
  OpenFormModalOptions,
  PromiseInfo,
  ShowFormModalResponse,
} from './types'
import { PrimaryButton, SecondaryButton } from '../ui/Button'

type Props = {
  show: boolean
  options: OpenFormModalOptions
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  promiseInfo: PromiseInfo<ShowFormModalResponse> | undefined
}

export const FormModal: React.FC<Props> = ({
  show,
  setShow,
  promiseInfo,
  options,
}) => {
  const {
    title,
    content,
    submitText,
    cancelText,
    buttonAlign = 'right',
  } = options

  const closeModal = () => {
    setShow(false)
    promiseInfo?.resolve({
      success: false,
      data: null,
    })
  }

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setShow(false)

    const formValues = new FormData(e.currentTarget)
    promiseInfo?.resolve({
      success: true,
      data: Object.fromEntries(formValues),
    })
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                <form onSubmit={submitForm} className="mt-4">
                  {content}

                    <div className={`mt-4 flex ${buttonAlign === 'right' ? 'justify-end' : 'justify-start'}`}>
                    <SecondaryButton onClick={closeModal}>
                      {cancelText || 'Cancel'}
                    </SecondaryButton>
                    <PrimaryButton type="submit" className="ml-2">
                      {submitText || 'Submit'}
                    </PrimaryButton>
                    </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default FormModal
