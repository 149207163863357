import React from 'react'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode
  className?: string
}

const ToolbarButton: React.FC<Props> = (props) => {
  return (
    <button
      {...props}
      className={`hover:bg-gray-200 text-sm text-gray-800 font-bold py-1 px-1 rounded inline-flex items-center ${props.className}`}
    >
      {props.children}
    </button>
  )
}

export default ToolbarButton
