import { useQuery } from 'react-query'
import { getFileV2 } from '../api'
import { useState } from 'react'
import { ExtractionMethod, TestDataOcr } from '../types'

const useFile = (id: string) => {
  const [pdf, setPDF] = useState<Blob | string>('')
  const [ocr, setOCR] = useState<TestDataOcr | undefined>(undefined)
  const [extractionMethod, setExtractionMethod] = useState<
    ExtractionMethod | undefined
  >()
  const [isLocalFile, setIsLocalFile] = useState(false)

  const { isError, isLoading } = useQuery(id, () => getFileV2(id), {
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setPDF(res.pdf)
      setOCR(res.ocr)
      setExtractionMethod(res.extractionMethod)
      setIsLocalFile(res.isLocal ? true : false)
    },
    enabled: !!id,
  })

  return {
    pdf,
    ocr,
    isError,
    isLoading,
    extractionMethod,
    isLocalFile,
  }
}

export default useFile
