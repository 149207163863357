import currency from 'currency.js'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { TextBlock } from '../types'
import { extractAndParseDate } from './date-util'

dayjs.extend(customParseFormat)

// TODO: adding more date formats will increase the data match process significantlly
/**
 * We should either let users to specify the date format
 * or look for a better solution than dayjs
 */
export const Date_Formats = [
  'D-MMMM-YYYY', // 1-July-2024
  'DD-MMMM-YYYY', // 01-July-2024
  'D-MMM-YYYY', //  1-Jul-2024
  'DD-MMM-YYYY', // 01-Jul-2024
  'D/MM/YYYY', // 1/07/2024
  'DD/MM/YYYY', // 01/07/2024
  'D/M/YYYY', // 1/7/2024
  'DD/M/YYYY', // 01/7/2024
  'D MMM YYYY', // 1 Jul 2024
  'DD MMM YYYY', // 01 Jul 2024
  'YYYY MM DD', // 2024 07 01
  'YYYY-MM-DD', // 2024-07-01
  'YYYY M D', // 2024 7 1
  'YYYY-M-D', // 2024-7-1
  'MMMM D, YYYY', // July 1, 2024
  'MMMM DD, YYYY', // July 01, 2024
  'MMM D, YYYY', // Jul 1, 2024
  'MMM DD, YYYY', // Jul 01, 2024
  'DD/MMM/YYYY', // 01/Jul/2024
  'MMM/DD/YYYY', // Jul/01/2024
  'D MMMM YYYY', // 1 July 2024
  'DD MMMM YYYY', // 01 July 2024
  'MM/DD/YYYY', // 07/01/2024
  'M/DD/YYYY', // 7/01/2024
  'MM/D/YYYY', // 07/1/2024
  'M/D/YYYY', // 7/1/2024
  'YYYY/MM/DD', // 2024/07/01
  'YYYY/M/DD', // 2024/7/01
  'YYYY/MM/D', // 2024/07/1
  'YYYY/M/D', // 2024/7/1,
  'DD.MM.YYYY', // 01.01.2022
]

export const isPossibleDate = (str: string) => {
  const arr = str.split(' ')
  const arr1 = str.split('/')
  const arr2 = str.split('.')
  return arr.length === 3 || arr1.length === 3 || arr2.length === 3
}

export const fuzzyInputValue = (val: string) => {
  const date = extractAndParseDate(val)
  if (date) return date
  const amount = currency(val)
  const amountVal = amount.value
  if (!isNaN(amountVal) && amountVal !== 0 && !val.includes('/'))
    return amount.toString()
  return val
}

export const fuzzyTextBlockValue = (
  id: string,
  val: string,
  dateCache: string[]
) => {
  const cache = dateCache.find((cache) => cache.includes(id))
  if (cache) {
    const [date] = cache.split('::')
    return date
  }
  const amount = currency(val)
  const amountVal = amount.value
  if (!isNaN(amountVal) && amountVal !== 0 && !val.includes('/'))
    return amount.toString()
  return val
}

export const getDateBlocks = (textBlocks: TextBlock[]): string[] => {
  const dateBlocks = textBlocks
    .map((block) => {
      const date = extractAndParseDate(block.description)
      if (date) {
        return `${date}::${block.id}`
      }
      return null
    })
    .filter((val) => val !== null)
  const dateMap = new Map<string, string>()
  dateBlocks.forEach((block) => {
    if (block) {
      const [date, id] = block.split('::')
      dateMap.set(date, id)
    }
  })
  const result = []
  for (const [k, v] of dateMap) result.push(`${k}::${v}`)
  return result
}
