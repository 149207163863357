import { BrowserMetricsClient } from '@eplus/metrics-client/dist/browser'
import {
  MetricAggregateInput,
  MetricIngestInput,
} from '@eplus/metrics-client/dist/types'
import { getOfficeRuntimeAccessToken } from './common'
import { checkSubscriptionStatus } from '../api'

const METRICS_API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/metrics`

let metricsClient: BrowserMetricsClient
let firm_id: string
let user_id: string

const getMetricsClient = async () => {
  if (metricsClient) {
    return metricsClient
  }

  const { firmId } = await checkSubscriptionStatus()
  const token = await getOfficeRuntimeAccessToken()
  firm_id = firmId
  user_id = token.oid
  metricsClient = new BrowserMetricsClient({
    endpoint: METRICS_API_ENDPOINT!,
    headers: {},
    batchSize: 20,
  })
}

export async function addMetric(
  input: Omit<MetricIngestInput, 'firm_id' | 'user_id'>
) {
  await getMetricsClient()
  const metric: MetricIngestInput = {
    ...input,
    firm_id,
    user_id,
  }
  metricsClient.addMetric(metric)
}

export async function getMetrics(input: Omit<MetricAggregateInput, 'firm_id'>) {
  await getMetricsClient()
  const metric: MetricAggregateInput = {
    ...input,
    firm_id,
  }
  return metricsClient.getAggregatedMetrics(metric)
}
