import { Dispatch, useEffect, useMemo, useState } from 'react'
import { createFolder, createRecordBatch } from '../../api'
import {
  CreateRecordBatchRequest,
  FileRecord,
  FileStatus,
  FolderItem,
} from '../../types'
import { fileWithPathGuard } from '../../utils/guards'
import { saveFileRecordsToLocal } from '../../workbook'
import numeral from 'numeral'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDropzone, FileWithPath } from 'react-dropzone'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CloseIcon from '@mui/icons-material/Close'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { getOfficeRuntimeAccessToken } from '../../utils/common'
import { Upload_API_ERROR } from '../../constant'
import { createWorkerFactory } from '@shopify/web-worker'
import { last } from 'lodash'
import { PrimaryButton, SecondaryOutlineButton } from '../ui/Button'

const filesSanityCheck = (files: FileWithPath[]) => {
  const allowedFileTypes = new Set([
    'application/pdf',
    'image/jpeg',
    'image/png',
  ])
  return (
    files.every((file) => file.size < numeral('15MB').value()!) &&
    files.every((file) => allowedFileTypes.has(file.type))
  )
}

const pdfLessThan15MB = (size: number) => size < numeral('15MB').value()!

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type Props = {
  setUpload: Dispatch<React.SetStateAction<boolean>>
  refetchFiles: () => void
  loading: boolean
  setLoading: Dispatch<React.SetStateAction<boolean>>
  folderStack?: FolderItem[]
}

const createWorker = createWorkerFactory(
  () => import('../../workers/upload_worker')
)

export default function UploadContainer({
  setUpload,
  refetchFiles,
  loading,
  setLoading,
  folderStack,
}: Props) {
  const [dragOver, setDragOver] = useState(false)
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    onDragOver: () => setDragOver(true),
    onDragLeave: () => setDragOver(false),
    onDrop: () => setDragOver(false),
  })
  const [files, setFiles] = useState<FileWithPath[]>([])
  const [folderSet, setFolderSet] = useState<string[]>([])
  useEffect(() => {
    const set = new Set<string>()
    const filteredFiles = acceptedFiles.filter(fileWithPathGuard)
    filteredFiles.forEach((file) => {
      const path = file.path?.split('/')
      if (path && path.length > 1) set.add(path[1])
    })
    setFiles(filteredFiles)
    setFolderSet([...set])
  }, [acceptedFiles])

  const uploadFiles = async () => {
    // let filesForEstimation: any[] = []
    setLoading(true)
    setUpload(false)
    const func = async () => {
      if (!files.length) return []
      const fileRecord: FileRecord[] = []
      const { oid } = await getOfficeRuntimeAccessToken()
      const signedUrls: {
        signedUrl: string
        id: string
      }[] = []

      const parentFolderId = last(folderStack)?.id

      const filteredFiles = files.filter((file) => file.path?.split('/'))

      /**
       * Create folder from paths
       */
      const createdFolderPathMap = new Map<string, string>()
      const parentFolderIdItems: {
        path: string
        parentFolderId: string | undefined
      }[] = []
      for (const path of filteredFiles.map((file) => file.path!)) {
        let ppfid: string | undefined = parentFolderId
        const arr = path.split('/')
        const folderNames = arr.slice(1, arr.length - 1)
        if (folderNames.length > 0) {
          let folderPath = '/'
          for (const folderName of folderNames) {
            folderPath += `${folderName}/`
            if (createdFolderPathMap.has(folderPath)) {
              ppfid = createdFolderPathMap.get(folderPath)
            } else {
              const folderId = await createFolder(ppfid, folderName)

              fileRecord.push({
                fileId: folderId,
                userId: oid,
                status: FileStatus.SUCCEEDED,
              })

              ppfid = folderId

              createdFolderPathMap.set(folderPath, ppfid)
            }
          }
        }

        parentFolderIdItems.push({
          path,
          parentFolderId: ppfid,
        })
      }

      /**
       * Create record batch
       */
      const payload: CreateRecordBatchRequest['files'] = filteredFiles.map(
        (file) => {
          const arr = file.path?.split('/')!

          const parentFolderId = parentFolderIdItems.find(
            (f) => f.path === file.path
          )?.parentFolderId
          return {
            parentFolderId: parentFolderId ?? null,
            fileName: file.name,
            dir: arr.length <= 1 ? `/${file.path}` : file.path!,
            contentType: file.type,
          }
        }
      )

      const results = await createRecordBatch(payload)

      results.data.forEach(({ fileId, uploadURL }) => {
        fileRecord.push({
          fileId: fileId,
          userId: oid,
          status: FileStatus.PROCESSING,
          isLocal: false,
        })
        signedUrls.push({ id: fileId, signedUrl: uploadURL })
      })

      await saveFileRecordsToLocal(fileRecord).catch((err) =>
        console.error('saveFileRecordsToLocal:', err)
      )
      return signedUrls
    }
    const checked = filesSanityCheck(files)
    if (!checked) {
      toast.error('Invalid file type or file size exceeds 5MB')
      setLoading(false)
      return
    }
    func()
      .then(async (signedUrls) => {
        // for (let i = 0; i < signedUrls.length; i++) {
        //   const file = files[i]
        //   const { id, signedUrl } = signedUrls[i]
        //   const buffer = await file.arrayBuffer()
        //   // let pageCount = 1
        //   // if (file.type === 'application/pdf') {
        //   //   const pdfDoc = await PDFDocument.load(buffer)
        //   //   pageCount = pdfDoc.getPageCount()
        //   // }
        //   const blob = new Blob([buffer])
        //   await putFileToSignedURL(signedUrl, new File([blob], file.name))
        //   await startProcessingDoc(id, file.type) // Trigger processing of Doc after upload
        //   // filesForEstimation.push({
        //   //   fileId: id,
        //   //   totalPages: pageCount,
        //   // })
        // }
        const uploadWorker = createWorker()
        const promises = signedUrls.map((signedUrlObj, i) => {
          const file = files[i]
          const { id, signedUrl } = signedUrlObj
          return uploadWorker.uploadFile(file, id, signedUrl)
        })
        setLoading(false)
        await Promise.all(promises)
        // await Promise.all(promises)
        refetchFiles()
      })
      // .then(() => {
      //   refetchFiles() // Pass the parameters here
      //   setLoading(false)
      // })
      .catch((err) => {
        setLoading(false)
        console.error(err)
        toast.error(Upload_API_ERROR, { autoClose: false })
        toast.error(`Details: ${err}`, { autoClose: false })
      })
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const removeAllFiles = () => {
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    setFiles([])
    setFolderSet([])
  }

  const removeFile = (file: FileWithPath) => () => {
    const filteredFiles = files.filter(
      (f) => f.name !== file.name || f.path !== file.path
    )
    const idx = acceptedFiles.findIndex(
      (f) => f.name === file.name && (f as FileWithPath).path === file.path
    )

    if (idx >= 0) {
      acceptedFiles.splice(idx, 1)
    }
    setFiles(filteredFiles)
  }

  const removeFolder = (startPath: string) => () => {
    const filteredFolders = folderSet.filter((folder) => folder !== startPath)
    const remainingFiles = files.filter(
      (file) => !file.path?.startsWith(`/${startPath}`)
    )
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    remainingFiles.forEach((file) => acceptedFiles.push(file))
    setFolderSet(filteredFolders)
    setFiles(remainingFiles)
  }

  const folderContainsPdfLessThan5MB = (path: string) => {
    const filteredFiles = files.filter((file) =>
      file.path?.startsWith(`/${path}`)
    )
    return filteredFiles.every((file) => pdfLessThan15MB(file.size))
  }

  return (
    <div className="h-full min-w-[300px] ">
      {!loading && (
        <>
          <div className="flex flex-col p-4 pb-0 h-[calc(100vh_-_70px)]">
            <div
              className={`p-4 rounded-lg mb-2 ${
                dragOver ? 'bg-green-100/50 opacity-50' : 'bg-white'
              }`}
            >
              <div
                {...getRootProps({ style })}
                className={`${
                  dragOver ? 'transition ease-in-out delay-75 scale-110' : ''
                }`}
              >
                <input {...getInputProps()} />
                <div className={`${dragOver ? '' : 'animate-pulse'}`}>
                  <CloudUploadOutlinedIcon sx={{ fontSize: 68 }} />
                </div>
                <div className="text-lg text-black font-semibold mb-1">
                  Drag & drop files/folders or{' '}
                  <span className="text-[#089797] cursor-pointer flex justify-center">
                    Browse
                  </span>
                </div>
                <div className="text-sm text-[#606372]">
                  PDF/Img only, file size no more that 15MB
                </div>
              </div>
            </div>
            {files.length > 0 && (
              <div className="flex flex-col bg-white rounded-lg p-4 max-h-[calc(100%_-_220px)] overflow-y-auto">
                <ul>
                  {folderSet.length > 0 &&
                    folderSet.map((folder, i) => (
                      <li key={i}>
                        <div
                          className={`flex border-2 ${
                            folderContainsPdfLessThan5MB(folder)
                              ? 'border-[#089797]'
                              : 'border-[#EF4444]'
                          }  mb-1 rounded-md justify-between p-2`}
                        >
                          <div className="flex">
                            <div className="flex text-black font-semibold mr-2 items-center">
                              <FolderOutlinedIcon sx={{ fontSize: 20 }} />
                            </div>
                            <div className="flex text-black items-center text-sm font-semibold">
                              {folder}
                            </div>
                          </div>
                          <div>
                            <button
                              className="transition ease-in-out delay-100 hover:scale-125"
                              onClick={removeFolder(folder)}
                            >
                              <CloseIcon sx={{ fontSize: 20 }} />
                            </button>
                          </div>
                        </div>
                        {!folderContainsPdfLessThan5MB(folder) && (
                          <div className="text-sm text-[#EF4444]">
                            This folder contains file more than 15MB, please
                            delete and upload the folder again.
                          </div>
                        )}
                      </li>
                    ))}
                  {files
                    .filter(
                      (file) =>
                        !folderSet.some(
                          (path) => file.path?.startsWith(`/${path}`) ?? false
                        )
                    )
                    .map((file) => (
                      <li className="mb-1" key={`${file.path}-${file.name}`}>
                        <div
                          className={`flex border-2 ${
                            pdfLessThan15MB(file.size)
                              ? 'border-[#089797]'
                              : 'border-[#EF4444]'
                          } rounded-md justify-between p-2`}
                        >
                          <div className="flex">
                            <div className="flex  text-black font-semibold mr-2">
                              {file.name}
                            </div>
                            <div className="flex text-black items-center text-sm font-light">
                              {numeral(file.size).format('0b')}
                            </div>
                          </div>

                          <div>
                            <button
                              className="transition ease-in-out delay-100 hover:scale-125"
                              onClick={removeFile(file)}
                            >
                              <CloseIcon sx={{ fontSize: 20 }} />
                            </button>
                          </div>
                        </div>
                        {!pdfLessThan15MB(file.size) && (
                          <div className="text-sm text-[#EF4444]">
                            This file is over 5MB, please delete and upload
                            another file.
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
      {!loading && files.length > 0 && (
        <footer className="absolute flex justify-end  bottom-0 bg-white w-full rounded-t-3xl h-[60px]">
          <div className="flex p-2">
            <SecondaryOutlineButton onClick={removeAllFiles}>
              Cancel
            </SecondaryOutlineButton>

            <PrimaryButton onClick={uploadFiles}>Process</PrimaryButton>
          </div>
        </footer>
      )}
    </div>
  )
}
