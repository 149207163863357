import React, { useEffect } from 'react'
import Checkbox from '../ui/Checkbox'
import Listbox, { ListboxProps } from '../ui/Listbox'
import { TextcutRectangle } from '../../types'

interface Props {
  textCutRect: TextcutRectangle
}

const TableOptionsModalContent: React.FC<Props> = ({ textCutRect }) => {
  const [hasHeader, setHasHeader] = React.useState(true)
  const [cols, setCols] = React.useState([] as ListboxProps['data'])
  const [alignOptions, setAlignOptions] = React.useState(
    [] as ListboxProps['data']
  )

  const [currentGroupByColId, setCurrentGroupByColId] = React.useState<
    string | undefined
  >()
  const [currentColTextAlignment, setCurrentColTextAlignment] = React.useState<
    string | undefined
  >()

  const [tableOptions, setTableOptions] = React.useState(
    textCutRect.tableOptions
  )

  useEffect(() => {
    setTableOptions(tableOptions)
    if (tableOptions && 'hasHeader' in tableOptions) {
      setHasHeader(tableOptions.hasHeader)
    }
  }, [tableOptions])

  useEffect(() => {
    const { values: tableData } = textCutRect
    const firstRowValues = tableData ? tableData[0] : []

    const data: ListboxProps['data'] = [{ id: '-1', name: "Don't merge rows" }]

    data.push(
      ...firstRowValues.map((name, i) => ({
        id: i.toString(),
        name: `Column ${i + 1}` + (name ? ` (${name})` : ''),
      }))
    )
    // if (hasHeader) {
    //   data.push(
    //     ...firstRowValues.map((name, i) => ({
    //       id: i.toString(),
    //       name: `Column ${i + 1}` + (name ? ` (${name})` : ''),
    //     }))
    //   )
    // } else {
    //   data.push(
    //     ...firstRowValues.map((_, i) => ({
    //       id: i.toString(),
    //       name: `Column ${i + 1}`,
    //     }))
    //   )
    // }

    if (currentGroupByColId) {
      const groupByCol = data.find((d) => d.id === currentGroupByColId)
      if (groupByCol) {
        groupByCol['selected'] = true
      }
    } else if (tableOptions?.groupByColId) {
      const groupByCol = data.find((d) => d.id === tableOptions.groupByColId)
      if (groupByCol) {
        groupByCol['selected'] = true
      }
    }

    setCols(data)

    const alignOptions: ListboxProps['data'] = [
      { id: 'top', name: 'Top' },
      // { id: 'middle', name: 'Middle' }, // Not supported yet
      { id: 'bottom', name: 'Bottom' },
    ]

    if (currentColTextAlignment) {
      const alignOption = alignOptions.find(
        (d) => d.id === currentColTextAlignment
      )
      if (alignOption) {
        alignOption['selected'] = true
      }
    } else if (tableOptions?.colTextAlignment) {
      const alignOption = alignOptions.find(
        (d) => d.id === tableOptions.colTextAlignment
      )
      if (alignOption) {
        alignOption['selected'] = true
      }
    }
    setAlignOptions(alignOptions)
  }, [
    hasHeader,
    setCols,
    setAlignOptions,
    setHasHeader,
    textCutRect,
    tableOptions,
    currentGroupByColId,
    currentColTextAlignment,
  ])

  return (
    <div className="flex flex-col gap-5 py-2 text-sm text-gray-600">
      <Checkbox
        name="hasHeader"
        label="Table headers are present in the table"
        onChange={(e) => setHasHeader(e.target.checked)}
        defaultChecked={tableOptions?.hasHeader ?? true}
      ></Checkbox>
      <div className="flex flex-col gap-4">
        <div className="gap-4">
          <span className="block mb-1 ">Merge rows based on column</span>
          {cols.length && (
            <Listbox
              name="groupByCol"
              data={cols}
              onChange={(e) => setCurrentGroupByColId(e.id)}
            />
          )}
        </div>
        <div className="gap-4">
          <span className="block mb-1">
            Text alignment of the selected column
          </span>
          {alignOptions.length && (
            <Listbox
              name="colTextAlignment"
              data={alignOptions}
              onChange={(e) => setCurrentColTextAlignment(e.id)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TableOptionsModalContent
