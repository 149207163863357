import { Coordinates, ExtractionMethod } from '../../types'
import { CustomWord } from './types'

export type TableCell = {
  row: number
  col: number
  words: CustomWord[]
  coords: Coordinates
}

export const getRowLinesFromWords = (
  words: CustomWord[],
  extractionMethod: ExtractionMethod | undefined,
  tolerance = 1
): number[] => {
  // Sort blocks by the top edge of their bounding box (vertical position).
  words.sort((a, b) => {
    const aYMin = a.coords[1]
    const bYMin = b.coords[1]
    return aYMin - bYMin
  })

  const rowPositions: { yMin: number; yMax: number }[] = []
  let currentLineYMin: number | null = null
  let currentLineYMax: number | null = null

  words.forEach((word) => {
    const wordYMin = word.coords[1]
    const wordYMax = word.coords[3]
    const wordCenterY = (wordYMin + wordYMax) / 2

    // Check if word belongs to the current line
    if (
      currentLineYMax === null ||
      Math.abs(wordCenterY - currentLineYMax) < tolerance
    ) {
      // If current line is set or word is within tolerance, update current line Y min and max
      currentLineYMin =
        currentLineYMin === null
          ? wordYMin
          : Math.min(currentLineYMin, wordYMin)
      currentLineYMax =
        currentLineYMax === null
          ? wordYMax
          : Math.max(currentLineYMax, wordYMax)
    } else {
      // Push current line Y min and max to lines and start a new line
      rowPositions.push({ yMin: currentLineYMin!, yMax: currentLineYMax! })

      currentLineYMin = wordYMin
      currentLineYMax = wordYMax
    }
  })

  // Add the last line if not empty
  if (currentLineYMax !== null && currentLineYMin !== null) {
    rowPositions.push({ yMin: currentLineYMin, yMax: currentLineYMax })
  }

  // Merge rowPositions if they intersect
  const mergedRowPositions: { yMin: number; yMax: number }[] = []
  const iter = rowPositions[Symbol.iterator]()
  let curr = iter.next()
  let currentRow = curr.value!
  while (!curr.done) {
    curr = iter.next()
    const nextRow = curr.value

    if (nextRow) {
      const intersectionRatio = getCoordsIntersectionArea(
        [0, currentRow.yMin, 1, currentRow.yMax],
        [0, nextRow.yMin, 1, nextRow.yMax]
      )

      if (intersectionRatio > 0.5) {
        currentRow = {
          yMin: Math.min(currentRow.yMin, nextRow.yMin),
          yMax: Math.max(currentRow.yMax, nextRow.yMax),
        }
      } else {
        mergedRowPositions.push(currentRow)
        currentRow = nextRow
      }
    }
  }

  // Add the last row
  mergedRowPositions.push(currentRow)

  console.log('mergedRowPositions', mergedRowPositions)

  // Get the lines
  const lines: number[] = []
  if (extractionMethod === 'PDF_OCR') {
    mergedRowPositions.forEach((row) => {
      lines.push(row.yMax)
    })
  } else {
    /**
     * Taking yMin since for some reason yMax doesn't align well with the text
     */
    for (let i = 1; i < mergedRowPositions.length; i++) {
      lines.push(mergedRowPositions[i].yMin)
    }
    lines.push(mergedRowPositions[mergedRowPositions.length - 1].yMax)
  }

  return lines
}

/**
 * @param pos1  [x1, y1, x2, y2]
 * @param pos2  [x1, y1, x2, y2]
 * @returns  The ratio of the intersection (value between 0 and 1)
 */
export const getCoordsIntersectionArea = (
  pos1: number[],
  pos2: number[]
): number => {
  const [x1, y1, x2, y2] = pos1
  const [x3, y3, x4, y4] = pos2

  const area1 = Math.abs((x2 - x1) * (y2 - y1))

  // Calculate the coordinates of the intersection rectangle
  const interLeft = Math.max(x1, x3)
  const interRight = Math.min(x2, x4)
  const interTop = Math.max(y1, y3)
  const interBottom = Math.min(y2, y4)

  // Calculate width and height of the intersection
  const interWidth = Math.max(0, interRight - interLeft)
  const interHeight = Math.max(0, interBottom - interTop)

  // Calculate the area of intersection
  const interArea = interWidth * interHeight

  // Check if the intersection area is at least the specified percent of rect1's area
  const ratio = interArea / area1
  return ratio
}

export const mergeCoordindates = (
  coords1: Coordinates,
  coords2: Coordinates
): Coordinates => {
  const [x1, y1, x2, y2] = coords1
  const [x3, y3, x4, y4] = coords2
  return [
    Math.min(x1, x3),
    Math.min(y1, y3),
    Math.max(x2, x4),
    Math.max(y2, y4),
  ]
}

export const mergeTableRows = (rows: TableCell[][]): TableCell[] => {
  if (rows.length < 2) {
    return rows.flat()
  }
  const mergedRows: TableCell[] = []

  const colLen = rows[0].length
  const rowIndex = Math.min(...rows.map((row) => row[0].row))
  for (let col = 0; col < colLen; col++) {
    const words: CustomWord[] = []
    const rowLen = rows.length
    for (let ridx = 0; ridx < rowLen; ridx++) {
      const rowWords = rows[ridx][col].words

      words.push(...rowWords)
    }

    mergedRows.push({
      row: rowIndex,
      col,
      words,
      coords: mergeCoordindates(
        rows[0][col].coords,
        rows[rows.length - 1][col].coords
      ),
    })
  }

  return mergedRows
}
