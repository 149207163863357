import { filter, reduce } from 'lodash'
import {
  BlockType,
  BoundingBox,
  ParseTableResult,
  ParseTableResultCellData,
  QuadTreeMiniTextBlock,
  TextBlock,
  TextcutRectangle,
  UserGeneratedLine,
  Vertex,
} from '../types'
import { Rectangle } from '@timohausmann/quadtree-ts'
import numeral from 'numeral'
import { TextItem } from 'pdfjs-dist/types/src/display/api'
import { nanoid } from 'nanoid'
import {
  lineMidpoint,
  Polygon,
  polygonArea,
  polygonInPolygon,
  polygonIntersectsPolygon,
} from 'geometric'

type height = number
type width = number

export const calculateHeightandWidth = (
  v1: Vertex,
  v2: Vertex,
  v3: Vertex,
  v4: Vertex
): [height, width] => {
  const xS = [v1.x, v2.x, v3.x, v4.x]
  const yS = [v1.y, v2.y, v3.y, v4.y]

  const maxX = Math.max(...xS)
  const maxY = Math.max(...yS)

  const minX = Math.min(...xS)
  const minY = Math.min(...yS)

  return [maxY - minY, maxX - minX]
}

export const findTOpLeftPointFromVertices = (vertices: Vertex[]) => {
  return reduce(vertices, (acc, point) => ({
    x: Math.min(acc.x, point.x),
    y: Math.min(acc.y, point.y),
  }))
}

export const filterNodes = (
  rect: Rectangle,
  nodes: Rectangle<QuadTreeMiniTextBlock>[]
) => {
  // const filtered = filter(nodes, (node) => {
  //   return (
  //     node.x >= rect.x &&
  //     node.y >= rect.y &&
  //     rect.x + rect.width >= node.x + node.width &&
  //     rect.y + rect.height >= node.y + node.height
  //   )
  // })
  const rectPoly: Polygon = [
    [rect.x, rect.y],
    [rect.x + rect.width, rect.y],
    [rect.x + rect.width, rect.y + rect.height],
    [rect.x, rect.y + rect.height],
  ]

  const filtered = filter(nodes, (node) => {
    const nodePoly: Polygon = [
      [node.x, node.y],
      [node.x + node.width, node.y],
      [node.x + node.width, node.y + node.height],
      [node.x, node.y + node.height],
    ]
    return (
      polygonInPolygon(nodePoly, rectPoly) ||
      (polygonIntersectsPolygon(nodePoly, rectPoly) &&
        getIntersectionPercentage(nodePoly, rectPoly) >= 0.8)
    )
  })
  return filtered
}

const getRectangleMidPoint = (
  rect: Rectangle<QuadTreeMiniTextBlock>
): [number, number] => [
  numeral(rect.x).multiply(2).add(rect.width).divide(2).value() ?? 0,
  numeral(rect.y).multiply(2).add(rect.height).divide(2).value() ?? 0,
]

export const sortByReadingOrder = <T>(
  items: T[],
  fn: (item: T) => number[],
  tolerance = 10
) => {
  return items.sort((a, b) => {
    const [x1a, y1a, x2a, y2a] = fn(a)
    const [x1b, y1b, x2b, y2b] = fn(b)

    const aMidPoint = [(x1a + x2a) / 2, (y1a + y2a) / 2]
    const bMidPoint = [(x1b + x2b) / 2, (y1b + y2b) / 2]

    const yDifference = aMidPoint[1] - bMidPoint[1]
    if (Math.abs(yDifference) < tolerance) return aMidPoint[0] - bMidPoint[0]
    else return yDifference
  })
}

export const sortTextcutInReadableWay = (
  arr: Rectangle<QuadTreeMiniTextBlock>[]
) => {
  return arr.sort((a, b) => {
    const [aX, aY] = getRectangleMidPoint(a)
    const [bX, bY] = getRectangleMidPoint(b)
    const yDiff = aY - bY
    if (Math.abs(yDiff) < 10) return aX - bX
    else return yDiff
  })
}

export const sortTextBlockInReadableWay = (blocks: TextBlock[]) =>
  blocks.sort((a, b) => {
    const [aX, aY] = getTextBlockMidPoint(a)
    const [bX, bY] = getTextBlockMidPoint(b)
    const yDiff = aY - bY
    if (Math.abs(yDiff) < 10) return aX - bX
    else return yDiff
  })

export const getTextBlockMidPoint = (block: TextBlock): [number, number] => {
  const [topLeft, topRight, bottomRight] = block.boundingPoly.vertices
  const xMid = lineMidpoint([
    [topLeft.x, topLeft.y],
    [topRight.x, topRight.y],
  ])
  const yMid = lineMidpoint([
    [topRight.x, topRight.y],
    [bottomRight.x, bottomRight.y],
  ])
  return [xMid[0], yMid[1]]
}

export const rotateOcrBox = (
  box: Vertex[],
  degree: number,
  pageWidth: number,
  pageHeight: number
) => {
  const [boxH, boxW] = calculateHeightandWidth(box[0], box[1], box[2], box[3])
  const topLeft = findTOpLeftPointFromVertices(box)
  const x = topLeft?.x ?? 0
  const y = topLeft?.y ?? 0
  let newX: number, newY: number, newW: number, newH: number
  switch (degree) {
    case 90:
      newX = pageHeight - (y + boxH)
      newY = x
      newW = boxH
      newH = boxW
      break
    case 180:
      newX = pageWidth - (x + boxW)
      newY = pageHeight - (y + boxH)
      newW = boxW
      newH = boxH
      break
    case 270:
      newX = y
      newY = pageWidth - (x + boxW)
      newW = boxH
      newH = boxW
      break
    default:
      newX = x
      newY = y
      newW = boxW
      newH = boxH
  }

  return [
    { x: newX, y: newY },
    { x: newX + newW, y: newY },
    { x: newX + newW, y: newY + newH },
    { x: newX, y: newY + newH },
  ]
}

export const convertToFourPointVertices = (
  x: number,
  y: number,
  width: number,
  height: number
): Vertex[] => {
  return [
    { x, y },
    { x: x + width, y },
    { x: x + width, y: y + height },
    { x, y: y + height },
  ]
}

export const rotateARectangle = (rect: TextcutRectangle, degree: number) => {
  const input = convertToFourPointVertices(rect.x, rect.y, rect.w, rect.h)
  const arr = rotateOcrBox(input, degree, rect.ocrW, rect.ocrH)
  const topLeft = findTOpLeftPointFromVertices(arr)
  const [hh, ww] = calculateHeightandWidth(arr[0], arr[1], arr[2], arr[3])
  if (!topLeft) return rect
  if (degree % 360 === 90 || degree % 360 === 270) {
    const tmpH = rect.ocrH
    const tmpW = rect.ocrW
    return {
      ...rect,
      x: topLeft.x,
      y: topLeft.y,
      h: hh,
      w: ww,
      ocrH: tmpW,
      ocrW: tmpH,
    }
  }
  rect = { ...rect, x: topLeft.x, y: topLeft.y, h: hh, w: ww }
  return rect
}

export const rotateAVertex = (
  x: number,
  y: number,
  width: number,
  height: number,
  degree: number
) => {
  switch (degree) {
    case 0:
      return [x, y, width, height]
    case 360:
      return [x, y, width, height]
    case 90:
      return [height - y, x, height, width]
    case 180:
      return [width - x, height - y, width, height]
    default:
      return [y, width - x, height, width]
  }
}

export const getBoundingBoxOfPdfTextItem = (textItem: TextItem) => {
  const [, , , , e, f] = textItem.transform
  const width = textItem.width
  const height = textItem.height

  // Bottom-left corner
  const x1 = e
  const y1 = f

  // Top-left corner
  const x2 = e
  const y2 = f + height

  // Bottom-right corner
  const x3 = e + width
  const y3 = f

  // Top-right corner
  const x4 = e + width
  const y4 = f + height

  return {
    bottomLeft: { x: x1, y: y1 },
    topLeft: { x: x2, y: y2 },
    bottomRight: { x: x3, y: y3 },
    topRight: { x: x4, y: y4 },
  }
}

/**
 * Convert a bouding box, where [0, 0] is the bottom left corner
 * to where [0, 0] is top left corner
 * @param boudingBox
 * @param height
 */
export const convertBottomLeftBoudingBoxToTopLeft = (
  boundingBox: BoundingBox,
  height: number
) => {
  return {
    bottomLeft: {
      x: boundingBox.bottomLeft.x,
      y: height - boundingBox.bottomLeft.y,
    },
    topLeft: {
      x: boundingBox.topLeft.x,
      y: height - boundingBox.topLeft.y,
    },
    bottomRight: {
      x: boundingBox.bottomRight.x,
      y: height - boundingBox.bottomRight.y,
    },
    topRight: {
      x: boundingBox.topRight.x,
      y: height - boundingBox.topRight.y,
    },
  }
}

export const convertTextItemToTextBlock = (
  textItem: TextItem,
  height: number
): TextBlock => {
  const boundingBox = getBoundingBoxOfPdfTextItem(textItem)
  const transformedBoundingBox = convertBottomLeftBoudingBoxToTopLeft(
    boundingBox,
    height
  )
  return {
    blockType: BlockType.WORD,
    description: textItem.str,
    boundingPoly: {
      vertices: [
        transformedBoundingBox.topLeft,
        transformedBoundingBox.topRight,
        transformedBoundingBox.bottomRight,
        transformedBoundingBox.bottomLeft,
      ],
    },
    id: nanoid(),
  }
}

export const parseTextItem = (items: TextItem[]): TextItem[] => {
  const parsed: TextItem[] = []

  for (const item of items) {
    const words = splitWordsByPosition(item)
    parsed.push(...words)
  }

  return parsed
}

export const splitWordsByPosition = (item: TextItem): TextItem[] => {
  const items: TextItem[] = []
  const str = item.str
  const strLength = str.length

  if (strLength === 0) return items

  const UNIT = item.width / strLength
  let startX = item.transform[4]
  const startY = item.transform[5]

  let i = 0
  for (let j = 0; j < strLength; j++) {
    if (str[j] === ' ') {
      const sliced = str.slice(i, j)
      const wordWidth = (sliced.length / strLength) * item.width

      if (sliced.trim() !== '') {
        items.push({
          dir: item.dir,
          fontName: item.fontName,
          hasEOL: item.hasEOL,
          height: item.height,
          str: sliced,
          transform: [...item.transform.slice(0, 4), startX, startY],
          width: wordWidth,
        })
      }

      startX += wordWidth + UNIT
      i = j + 1
    }
  }

  // Handle the last word after the final space
  const lastSliced = str.slice(i)
  const lastWordWidth = (lastSliced.length / strLength) * item.width

  if (lastSliced.trim() !== '') {
    items.push({
      dir: item.dir,
      fontName: item.fontName,
      hasEOL: item.hasEOL,
      height: item.height,
      str: lastSliced,
      transform: [...item.transform.slice(0, 4), startX, startY],
      width: lastWordWidth,
    })
  }

  return items
}

export const convertKonvaLineToPdfLine = (
  line: number[],
  ratioX: numeral.Numeral,
  ratioY: numeral.Numeral
) => {
  return line.map((value, i) => {
    if (i % 2) {
      return ratioY.value() !== null ? ratioY.value()! * value + 1.5 : null
    } else {
      return ratioX.value() !== null ? ratioX.value()! * value + 1.5 : null
    }
  })
}

export const convetPdfLinesToKonvaLine = (
  lines: UserGeneratedLine[],
  ratioX: numeral.Numeral,
  ratioY: numeral.Numeral
): UserGeneratedLine[] => {
  return lines
    .map((line) => {
      const transformed = line.line.map((val, i) => {
        if (i % 2) {
          return numeral(val).divide(ratioY.value()).value()
        } else {
          return numeral(val).divide(ratioX.value()).value()
        }
      })
      return { id: line.id, line: transformed }
    })
    .filter((line) => !line.line.includes(null)) as UserGeneratedLine[]
}

export const isVertical = (line: number[]) => {
  const first = line.at(0)
  const third = line.at(2)
  if (!first || !third) return false
  return Math.floor(first) === Math.floor(third)
}

export const calculateCellsFromTable = (
  vLines: number[],
  hLines: number[]
): Polygon[][] => {
  const cells: Polygon[] = []
  for (let i = 0; i < vLines.length - 1; i++) {
    const x1 = vLines[i]
    const x2 = vLines[i + 1]
    for (let j = 0; j < hLines.length - 1; j++) {
      const y1 = hLines[j]
      const y2 = hLines[j + 1]
      const cell: Polygon = [
        [x1, y1],
        [x2, y1],
        [x2, y2],
        [x1, y2],
      ]
      cells.push(cell)
    }
  }
  cells.sort((a, b) => {
    if (a[0][1] !== b[0][1]) {
      return a[0][1] - b[0][1]
    }
    return a[0][0] - b[0][0]
  })
  const grid: Polygon[][] = []
  let row: Polygon[] = []
  let y = cells[0][0][1]

  for (const cell of cells) {
    const y1 = cell[0][1]
    if (y1 !== y) {
      grid.push(row)
      row = []
      y = y1
    }
    row.push(cell)
  }
  if (row.length > 0) grid.push(row)
  return grid
}

export const rectangleVerticesToRectangle = (
  vertices: Vertex[]
): Polygon | null => {
  if (vertices.length !== 4) return null
  return [
    [vertices[0].x, vertices[0].y],
    [vertices[1].x, vertices[1].y],
    [vertices[2].x, vertices[2].y],
    [vertices[3].x, vertices[3].y],
  ]
}

export const convertParseTableResult = (
  table: ParseTableResult,
  ratioW: number,
  ratioH: number
): ParseTableResult => {
  const parsedData = table.data.filter((row) =>
    row.some((item) => item.text !== '')
  ) // remove empty row
  const transposed = parsedData[0].map((_, i) =>
    parsedData.map((row) => row[i])
  )
  const filtered = transposed.filter((row) =>
    row.some((item) => item.text !== '')
  )
  const d = filtered[0]
    .map((_, i) => filtered.map((row) => row[i]))
    .map((row) =>
      row.map((cell): ParseTableResultCellData => {
        return {
          left: cell.left * ratioW,
          height: cell.height * ratioH,
          text: cell.text,
          top: cell.top * ratioH,
          width: cell.width * ratioW,
        }
      })
    )
  return {
    data: d,
    extraction_method: table.extraction_method,
    bottom: table.bottom * ratioH,
    height: table.height * ratioH,
    left: table.left * ratioW,
    page: table.page,
    right: table.right * ratioW,
    top: table.top * ratioH,
    width: table.width * ratioW,
  }
}

export const smoothParseTable = (
  table: ParseTableResult
): [number[], number[]] => {
  const { data } = table
  const ROW = data.length,
    COL = data[0].length
  const xs = [table.left]
  // console.log('data:', data)
  for (let i = 1; i < COL; i++) {
    const arr = []
    for (let j = 0; j < ROW; j++) {
      if (data[j][i].left !== 0) arr.push(data[j][i].left)
    }
    if (arr.length) xs.push(Math.min(...arr))
    else {
      xs.push(data[0][i - 1].left + data[0][i - 1].width + 5)
    }
  }
  xs.push(table.right)
  // console.log('xs:', xs)
  const ys = [table.top]
  for (let i = 1; i < ROW; i++) {
    const arr = []
    for (let j = 0; j < COL; j++) {
      if (data[i][j].top !== 0) arr.push(data[i][j].top)
    }
    if (arr.length) ys.push(Math.min(...arr))
    else {
      ys.push(data[i - 1][0].top + data[i - 1][0].height + 5)
    }
  }
  ys.push(table.bottom)
  // console.log('ys:', ys)
  return [xs, ys]
}

export const convertIntersectedCellToMatrix = (
  cells: TextBlock[],
  row: number,
  col: number,
  minRowIdx: number,
  minColIdx: number
): TextBlock[][] => {
  const result: any[][] = Array.from({ length: row }, () =>
    Array(col).fill(null)
  )
  cells.forEach((cell) => {
    if (cell.columnIndex && cell.rowIndex) {
      result[cell.rowIndex - minRowIdx][cell.columnIndex - minColIdx] = cell
    }
  })
  if (result.some((row) => row.includes(null))) {
    return []
  }
  return result as TextBlock[][]
}

export const smoothOcrTable = (
  table: readonly TextBlock[][],
  ROW: number,
  COL: number
): [number[], number[]] => {
  // console.log(ROW, COL)
  const xs: number[] = []
  for (let i = 0; i < COL; i++) {
    const arr: number[] = []
    for (let j = 0; j < ROW; j++) {
      arr.push(Math.floor(table[j][i].boundingPoly.vertices[0].x))
    }
    xs.push(Math.min(...arr))
  }
  const xArray: number[] = []
  for (let i = 0; i < ROW; i++) {
    xArray.push(Math.floor(table[i][COL - 1].boundingPoly.vertices[1].x))
  }
  xs.push(Math.max(...xArray))
  // console.log(xs)

  const ys: number[] = []
  for (let i = 0; i < ROW; i++) {
    const arr: number[] = []
    for (let j = 0; j < COL; j++) {
      arr.push(Math.floor(table[i][j].boundingPoly.vertices[0].y))
    }
    ys.push(Math.min(...arr))
  }
  const yArray: number[] = []
  for (let i = 0; i < COL; i++) {
    yArray.push(Math.floor(table[ROW - 1][i].boundingPoly.vertices[2].y))
  }
  ys.push(Math.max(...yArray))
  // console.log(ys)
  return [xs, ys]
}

export const getWordsInsideRect = (
  rect: { x: number; y: number; w: number; h: number },
  words: TextBlock[]
): TextBlock[] => {
  const { x, y, w, h } = rect
  return words.filter((word) => {
    const { x: wordX, y: wordY } = word.boundingPoly.vertices[0]
    return wordX >= x && wordX <= x + w && wordY >= y && wordY <= y + h
  })
}

export const getIntersectionPercentage = (
  rect: Polygon,
  other: Polygon
): number => {
  const area = polygonArea(rect)
  const intersectionArea = getIntersectionArea(rect, other)
  return area === 0 ? 0 : intersectionArea / area
}

const getIntersectionArea = (rect: Polygon, other: Polygon): number => {
  const xOverlap = Math.max(
    0,
    Math.min(rect[1][0], other[1][0]) - Math.max(rect[0][0], other[0][0])
  )
  const yOverlap = Math.max(
    0,
    Math.min(rect[2][1], other[2][1]) - Math.max(rect[0][1], other[0][1])
  )
  return xOverlap * yOverlap
}
