import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'

type Props = {
  setUpload: () => void
  createNewFolder: () => void
  showDownloadOptions: () => void
  showDeleteOptions: () => void
  showIncludeInExcel: () => void
  upload: boolean
  uploadOnProgress: boolean
  numberOfFiles: number
  allFilesProcessed: boolean
}

const MobileHeader = ({
  createNewFolder,
  setUpload,
  showDeleteOptions,
  showDownloadOptions,
  showIncludeInExcel,
  upload,
  uploadOnProgress,
  numberOfFiles,
  allFilesProcessed,
}: Props) => {
  return (
    <div className="flex justify-center items-center sm:hidden">
      <Menu>
        <MenuButton className="mr-3 transition ease-in-out delay-75 hover:scale-105">
          <ExpandCircleDownOutlinedIcon sx={{ fontSize: 20 }} />
        </MenuButton>
        <MenuItems
          anchor="bottom start"
          className="z-50 flex flex-col  bg-white/90 w-48 border-gray-200 rounded border gap-2"
        >
          <MenuItem disabled={upload || uploadOnProgress}>
            <button
              className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
              onClick={setUpload}
            >
              <div>
                <CloudUploadOutlinedIcon sx={{ fontSize: 20 }} />
              </div>
              <div className="flex-grow">Upload files</div>
            </button>
          </MenuItem>

          <MenuItem>
            <button
              className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
              onClick={createNewFolder}
              disabled={upload || uploadOnProgress}
            >
              <div>
                <CreateNewFolderOutlinedIcon sx={{ fontSize: 20 }} />
              </div>
              <div className="flex-grow">Create folder</div>
            </button>
          </MenuItem>

          <MenuItem
            disabled={
              upload || uploadOnProgress || !allFilesProcessed || !numberOfFiles
            }
          >
            <button
              className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
              onClick={showIncludeInExcel}
            >
              <div>
                <CloudDownloadOutlinedIcon sx={{ fontSize: 20 }} />
              </div>
              <div className="flex-grow">Include in Excel</div>
            </button>
          </MenuItem>

          <MenuItem disabled={upload || !allFilesProcessed || !numberOfFiles}>
            <button
              className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
              onClick={showDownloadOptions}
            >
              <div>
                <FileUploadOutlinedIcon sx={{ fontSize: 20 }} />
              </div>
              <div className="flex-grow">Export options</div>
            </button>
          </MenuItem>

          <MenuItem disabled={upload || !allFilesProcessed || !numberOfFiles}>
            <button
              className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
              onClick={showDeleteOptions}
            >
              <div>
                <DeleteOutlinedIcon sx={{ fontSize: 20 }} />
              </div>
              <div className="flex-grow">Delete options</div>
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
  )
}

export default MobileHeader
