import { useEffect } from 'react'
import { AppCustomEvent } from '../types'
import { isCustomEvent } from '../utils/guards'
import { useAppDispatch, useAppSelector } from '../dispatch'
import { isEmptyRange } from '../workbook'
import { deleteReferencesWithBindingId } from '../slice/referenceSlice'

const useBindingOnDataChangeEventReceiver = () => {
  const references = useAppSelector((state) => state.references.references)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const handler = async (event: Event) => {
      if (!isCustomEvent(event)) return
      const filtered = references.find(
        (ref) => ref.bindingId === event.detail?.bindingId
      )
      if (!filtered) return
      const isEmpty = await isEmptyRange(filtered.bindingId).catch((err) =>
        console.error(err)
      )
      if (isEmpty) {
        await dispatch(deleteReferencesWithBindingId(filtered.bindingId))
      }
    }

    window.addEventListener(AppCustomEvent.BINDING_ON_DATA_CHANGE, handler)

    return () =>
      window.removeEventListener(AppCustomEvent.BINDING_ON_DATA_CHANGE, handler)
  }, [references, dispatch])
}

export default useBindingOnDataChangeEventReceiver
