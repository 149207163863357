export class RetryStrategy {
    maxRetries;
    delay;
    constructor(maxRetries, delay) {
        this.maxRetries = maxRetries;
        this.delay = delay;
    }
    async execute(operation) {
        let lastError;
        for (let attempt = 0; attempt <= this.maxRetries; attempt++) {
            try {
                return await operation();
            }
            catch (error) {
                lastError = error;
                if (attempt < this.maxRetries - 1) {
                    console.warn(`Retry attempt ${attempt + 1}/${this.maxRetries}`);
                    await new Promise((resolve) => setTimeout(resolve, this.delay));
                }
            }
        }
        throw lastError;
    }
}
