import { Listbox as HeadlessListbox, Transition } from '@headlessui/react'
import { useState, Fragment, useEffect } from 'react'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

type ListboxData = {
  id: string
  name: string
  selected?: boolean
}

export interface ListboxProps {
  name?: string
  data: ListboxData[]
  onChange?: (e: ListboxData) => void
}

const Listbox = ({ name, data, onChange }: ListboxProps) => {
  const [selected, setSelected] = useState<ListboxData | undefined>(
    data.find((d) => d.selected) || data[0]
  )

  const handleChange = (e: ListboxData) => {
    setSelected(e)
    onChange?.(e)
  }

  useEffect(() => {
    const selectedData = data.find((d) => d.selected) || data[0]
    if (selectedData) setSelected(selectedData)
  }, [data])

  return (
    <HeadlessListbox name={name} value={selected} onChange={handleChange}>
      <HeadlessListbox.Button
        className={
          'relative w-full text-start p-2 rounded-md border border-input bg-background px-3 text-sm'
        }
      >
        <span className="block truncate">{selected?.name}</span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <UnfoldMoreIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </HeadlessListbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <HeadlessListbox.Options
          className={
            'overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 text-sm text-gray-600'
          }
        >
          {data.map((d) => (
            <HeadlessListbox.Option
              key={d.id}
              value={d}
              className={({ active }) =>
                `relative cursor-default select-none p-2 ${
                  active ? 'bg-gray-200 text-gray-800' : 'text-gray-600'
                }`
              }
            >
              {({ selected }) => (
                <div className="flex items-center">
                  {selected ? (
                    <span className="flex items-center text-green-400 mr-1">
                      <CheckOutlinedIcon sx={{ fontSize: 18 }} />
                    </span>
                  ) : null}
                  <span
                    className={`block truncate ${
                      selected ? 'font-medium' : 'font-normal'
                    }`}
                  >
                    {d.name}
                  </span>
                </div>
              )}
            </HeadlessListbox.Option>
          ))}
        </HeadlessListbox.Options>
      </Transition>
    </HeadlessListbox>
  )
}

export default Listbox
