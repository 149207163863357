import Reader from './Reader'
import { ReaderProps } from './types'
import { DocumentProps, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

type Props = ReaderProps & {
  file?: DocumentProps['file']
}

export default function PdfViewer(props: Props) {
  return <Reader {...props} virtualizerOptions={{ overscan: 5 }} />
}
