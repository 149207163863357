import { MouseEventHandler, useState } from 'react'

type DropdownButtonInput = {
  id: string
  label: string
  onClick: MouseEventHandler
  disable?: boolean
  itemDeletion?: [JSX.Element, MouseEventHandler]
}

type Props<T extends DropdownButtonInput> = {
  input: T[]
  icon: JSX.Element
  tooltip: string
  rearIcon?: JSX.Element
  customElem?: (t: T) => JSX.Element | undefined
  showLabel?: boolean
  dropdownClassName?: string
  closeOnClick?: boolean
}

const DropdownButton = <T extends DropdownButtonInput>({
  input,
  icon,
  tooltip,
  rearIcon,
  customElem,
  showLabel = true,
  dropdownClassName,
  closeOnClick = false,
}: Props<T>) => {
  const [open, setOpen] = useState(false)
  const elem = input.find((item) => window.location.pathname.includes(item.id))
  const filterd = input.filter(
    (item) => !window.location.pathname.includes(item.id)
  )
  return (
    <div className="relative flex text-nowrap justify-center	">
      <div
        className={`group relative flex ${
          elem && elem.label.length > 20 ? 'justify-start' : 'justify-center'
        }`}
      >
        <button
          className="inline-flex max-w-[124px] md:w-full items-center p-1 text-sm font-medium text-center text-gray-900 rounded-lg hover:bg-gray-200 max-h-10"
          type="button"
          onBlur={() => setOpen(false)}
          onClick={() => setOpen(!open)}
        >
          {icon}
          {showLabel && elem?.label && (
            <span className="ml-1 text-nowrap truncate">{elem.label}</span>
          )}
          {showLabel && rearIcon}
        </button>
        {/* <div className="absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100">
          {elem ? elem.label : tooltip}

        </div> */}
        <div
          className={`absolute top-14 scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0 ${
            elem && elem.label.length > 20
              ? 'before:right-80'
              : 'before:right-0'
          } before:mx-auto`}
        >
          {elem ? elem.label : tooltip}
        </div>
      </div>

      {open && filterd.length > 0 && (
        <div
          className={`absolute max-h-60 overflow-y-auto top-10 left-1 z-10  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${dropdownClassName}`}
        >
          <ul className="py-2 text-sm text-gray-700 ">
            {filterd.map((elem) => (
              <li className=" block " key={elem.id}>
                <div className="flex justify-center items-center">
                  <button
                    className={`w-full h-full text-left px-2 py-2 text-sm text-ellipsis truncate ${
                      elem?.disable ? 'opacity-50' : 'hover:bg-gray-100'
                    }`}
                    onMouseDown={elem.onClick}
                    onClick={() => closeOnClick && setOpen(false)}
                    disabled={elem?.disable}
                  >
                    {elem?.label}
                  </button>
                  {customElem && customElem(elem)}
                  {elem.itemDeletion && (
                    <button
                      onMouseDown={elem.itemDeletion[1]}
                      className="btn text-md hover:bg-gray-100 p-1"
                    >
                      {elem.itemDeletion[0]}
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default DropdownButton
