export class MetricQueue {
    batchSize;
    queue = [];
    constructor(batchSize) {
        this.batchSize = batchSize;
    }
    enqueue(metric) {
        this.queue.push(metric);
    }
    dequeueBatch() {
        return this.queue.splice(0, this.batchSize);
    }
    requeue(metrics) {
        this.queue.unshift(...metrics);
    }
    get length() {
        return this.queue.length;
    }
    shouldFlush() {
        return this.length >= this.batchSize;
    }
    toArray() {
        return [...this.queue];
    }
}
