import { FolderItem } from '../../types'
import CustomRadio from '../CustomRadio'

export const MovePopupHeader = ({
  header,
  type,
}: {
  header: string
  type: 'folder' | 'file'
}) => {
  return (
    <div>
      Move {type} {header}
    </div>
  )
}

type MovePopupContentProps = {
  options: {
    id?: string
    label: string
    description: string
    onClick: (...arr: any[]) => any
  }[]
  folderStack: FolderItem[]
}

export const MovePopupContent = ({ options, folderStack }: MovePopupContentProps) => {
  const path = folderStack.map((f) => f.name).join('/')
  return (
    <div className="flex flex-col gap-2">
      <div className="font-semibold">Current location: {path}</div>
      {options.length > 0 && (
        <div>
          <CustomRadio options={options} label="DOWNLOAD" />
        </div>
      )}
      {options.length === 0 && <div className="font-bold">Nil.</div>}
    </div>
  )
}
