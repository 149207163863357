import dayjs from 'dayjs'
import { db } from '../db/db'
import { ExtractlyPlusFile } from '../types'

export const addFileToDB = (file: ExtractlyPlusFile) => db.files.add(file)

export const fileInDB = (fileId: string) =>
  db.files.where({ id: fileId }).count()

export const getFileFromDB = (fileId: string) => db.files.get(fileId)

export const getFilesFromDB = (fileIds: string[]) =>
  db.files.where('id').anyOf(fileIds).toArray()

export const removeFileFromDB = (fileId: string) => db.files.delete(fileId)

export const revalidateFiles = async (day: number) => {
  const now = dayjs()

  const toBeDeleted = await db.files
    .filter((file) => {
      const fileCreatedAt = dayjs(file.createdAt)
      return now.diff(fileCreatedAt, 'day') > 7
    })
    .toArray()
  const toBeDeletedKeys = toBeDeleted.map((item) => item.id)
  await db.files.bulkDelete(toBeDeletedKeys)
}
