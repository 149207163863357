import Dexie, { EntityTable } from 'dexie'
import { ExtractlyPlusFile } from '../types'

const db = new Dexie('ExtractlyPlus') as Dexie & {
  files: EntityTable<ExtractlyPlusFile, 'id'>
}

db.version(1).stores({ files: 'id' })

export { db }
