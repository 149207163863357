import { MetricQueue } from './queue';
import { RetryStrategy } from './retry';
import { batchMetricIngestSchema, metricIngestSchema } from './schema';
export class BaseMetricsClient {
    transport;
    queue;
    retry;
    flushTimer = null;
    batchInterval;
    attributes = {};
    constructor(transport, options = {}) {
        this.transport = transport;
        const { batchSize = 10, batchInterval = 5000, retryCount = 3, retryDelay = 1000, } = options;
        this.queue = new MetricQueue(batchSize);
        this.batchInterval = batchInterval;
        this.retry = new RetryStrategy(retryCount, retryDelay);
    }
    async addMetric(payload) {
        console.debug('Adding metric:', payload);
        try {
            const validatedMetric = await metricIngestSchema.parseAsync({
                ...this.attributes,
                ...payload,
            });
            this.queue.enqueue(validatedMetric);
            if (this.queue.shouldFlush()) {
                await this.flush();
            }
            else if (!this.flushTimer) {
                this.flushTimer = setTimeout(() => this.flush(), this.batchInterval);
            }
        }
        catch (error) {
            console.error('Error queueing metric:', error);
        }
    }
    async addBatchMetric(payload) {
        console.debug('Adding batch metric:', payload);
        try {
            const validatedMetrics = await batchMetricIngestSchema.parseAsync(payload.map((metric) => ({
                ...this.attributes,
                ...metric,
            })));
            validatedMetrics.forEach((metric) => this.queue.enqueue(metric));
            if (this.queue.shouldFlush()) {
                await this.flush();
            }
            else if (!this.flushTimer) {
                this.flushTimer = setTimeout(() => this.flush(), this.batchInterval);
            }
        }
        catch (error) {
            console.error('Error queueing metric:', error);
        }
    }
    addAttributes(attributes) {
        this.attributes = { ...this.attributes, ...attributes };
    }
    getAttributes() {
        return this.attributes;
    }
    async flush() {
        if (this.queue.length === 0)
            return;
        const metrics = this.queue.dequeueBatch();
        this.flushTimer = null;
        try {
            await this.retry.execute(() => this.transport.send(metrics));
            console.log(`Successfully sent ${metrics.length} metrics`);
        }
        catch (error) {
            console.error('Failed to send metrics:', error);
            this.queue.requeue(metrics);
        }
    }
    async destroy() {
        if (this.flushTimer) {
            clearTimeout(this.flushTimer);
        }
        await this.flush();
    }
}
