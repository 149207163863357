import { Group, Layer, Rect } from 'react-konva'
import { Html } from 'react-konva-utils'
import { ClockLoader } from 'react-spinners'

type Props = {
  x: number
  y: number
  width: number
  height: number
  stroke: string
  fill: string
  strokeWidth: number
  isFetchingFromBE: boolean
}

const IsSelectingLayer = ({
  fill,
  height,
  stroke,
  strokeWidth,
  width,
  x,
  y,
  isFetchingFromBE,
}: Props) => {
  const midX = x + width / 2
  const midY = y + height / 2
  return (
    <Layer>
      <Group>
        <Rect
          x={x}
          y={y}
          width={width}
          height={height}
          stroke={stroke}
          fill={fill}
          strokeWidth={strokeWidth}
        />
        {isFetchingFromBE && (
          <Html
            transformFunc={(props) => {
              return {
                ...props,
                x: midX - 4,
                y: midY - 4,
              }
            }}
          >
            <div className="w-2 h-2">
              <ClockLoader size={20} color="#efac00" />
            </div>
          </Html>
        )}
      </Group>
    </Layer>
  )
}

export default IsSelectingLayer
