import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getOfficeRuntimeAccessToken } from '../utils/common'

type UserEmail = {
  email: string
}

const initialState: UserEmail = {
  email: '',
}

const userEmailSlice = createSlice({
  name: 'UserEmailSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(initUserEmail.fulfilled, (state, action) => {
      state.email = action.payload
    })
  },
})

export const initUserEmail = createAsyncThunk(
  'UserEmailSlice/init',
  async () => {
    const token = await getOfficeRuntimeAccessToken()
    return token.preferred_username
  }
)

export default userEmailSlice.reducer
