import { AwsRum, AwsRumConfig, PageAttributes } from 'aws-rum-web'
import { logger } from './logger'
import { AxiosError } from 'axios'
const version = require('../../package.json').version

declare global {
  interface Window {
    AwsRumInstance: AwsRum
  }
}

const getAwsRumInstance = () => window?.AwsRumInstance

const {
  REACT_APP_AWS_RUM_ENABLED,
  REACT_APP_AWS_RUM_APP_ID,
  REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
  REACT_APP_AWS_RUM_ENDPOINT,
  REACT_APP_AWS_RUM_APP_REGION,
  REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
} = getAppMonitorConfig()

export const initAwsRum = async () => {
  if (REACT_APP_AWS_RUM_ENABLED !== 'true') {
    return
  }

  logger.debug('Initializing AWS RUM web client')
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      identityPoolId: REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: REACT_APP_AWS_RUM_ENDPOINT,
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
      guestRoleArn: REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
      sessionAttributes: {
        'eplus:version': version,
      },
    }

    const APPLICATION_ID: string = REACT_APP_AWS_RUM_APP_ID
    const APPLICATION_VERSION: string = version
    const APPLICATION_REGION: string = REACT_APP_AWS_RUM_APP_REGION

    window.AwsRumInstance = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    )

    /**
     * Capture console errors
     */
    const originalConsoleError = console.error
    console.error = (...args) => {
      try {
        const instance = getAwsRumInstance()
        if (instance) {
          if (args[0] instanceof Error) {
            if (args[0] instanceof AxiosError) {
              let err = args[0] as AxiosError
              err.message =
                err.message + ': ' + JSON.stringify(err.response?.data)
              instance.recordError(err)
            } else {
              instance.recordError(args[0])
            }
          } else {
            instance.recordError(new Error(args.join(' ')))
          }
        }
      } catch (err) {
        // Ignore errors thrown while recording console errors
      }
      originalConsoleError.apply(console, args)
    }

    logger.debug('AWS RUM web client initialized')
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    logger.debug('Error initializing AWS RUM web client:', error)
  }
}

export const setAwsRumUser = ({
  userId,
  email,
}: {
  userId: string
  email: string
}) => {
  const instance = getAwsRumInstance()
  if (instance) {
    instance.addSessionAttributes({
      'eplus:userId': userId,
      'eplus:email': email,
    })
  }
}

function getAppMonitorConfig() {
  let REACT_APP_AWS_RUM_ENABLED = process.env.REACT_APP_AWS_RUM_ENABLED!
  const REACT_APP_AWS_RUM_APP_ID = process.env.REACT_APP_AWS_RUM_APP_ID!
  const REACT_APP_AWS_RUM_IDENTITY_POOL_ID =
    process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID!
  const REACT_APP_AWS_RUM_ENDPOINT = process.env.REACT_APP_AWS_RUM_ENDPOINT!
  const REACT_APP_AWS_RUM_APP_REGION = process.env.REACT_APP_AWS_RUM_APP_REGION!
  const REACT_APP_AWS_RUM_GUEST_ROLE_ARN =
    process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN!

  if (
    !REACT_APP_AWS_RUM_APP_ID ||
    !REACT_APP_AWS_RUM_IDENTITY_POOL_ID ||
    !REACT_APP_AWS_RUM_ENDPOINT ||
    !REACT_APP_AWS_RUM_APP_REGION ||
    !REACT_APP_AWS_RUM_GUEST_ROLE_ARN
  ) {
    logger.warn(
      'AWS RUM web client is enabled but not configured properly. Please check the environment variables.'
    )
    REACT_APP_AWS_RUM_ENABLED = 'false'
  }

  const config = {
    REACT_APP_AWS_RUM_ENABLED,
    REACT_APP_AWS_RUM_APP_ID,
    REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
    REACT_APP_AWS_RUM_ENDPOINT,
    REACT_APP_AWS_RUM_APP_REGION,
    REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
  }

  return config
}
