import { createContext } from 'react'
import { FormModalContextType } from './types'

const FormModalContext = createContext<FormModalContextType>({
  showFormModal: () => {
    throw new Error('Component is not wrapped with FormModalProvider')
  },
})

export default FormModalContext
