import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../dispatch'
import {
  deleteReferencesWithBindingGroupId,
  deleteReferencesWithBindingId,
} from '../slice/referenceSlice'
import { deleteBinding, undoRange } from '../workbook'
import { popUndoStack, pushToRedoStack } from '../slice/undoSlice'
import { CutTag, OfficeSimpleEvent } from '../types'

const getUndoRibbonRequestInput = (enabled: boolean) => ({
  tabs: [
    {
      id: 'QuickCut',
      groups: [{ id: 'DeleteGroup', controls: [{ id: 'Undo', enabled }] }],
    },
  ],
})

const useUndo = () => {
  const stack = useAppSelector((state) => state.undo.undoStack)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (stack.length) {
      Office.ribbon.requestUpdate(getUndoRibbonRequestInput(true))
    } else {
      Office.ribbon.requestUpdate(getUndoRibbonRequestInput(false))
    }
  }, [stack])

  useEffect(() => {
    const handler = async () => {
      if (!stack.length) return
      const item = stack.at(stack.length - 1)
      if (!item) return
      if (item.type === 'ADD') {
        deleteBinding(item.reference.bindingId)
          .then(async () => {
            if (item.reference.bindingGroupId) {
              await dispatch(
                deleteReferencesWithBindingGroupId(
                  item.reference.bindingGroupId
                )
              )
            } else {
              await dispatch(
                deleteReferencesWithBindingId(item.reference.bindingId)
              )
            }
          })
          .then(async () => {
            if (item.reference.tag !== CutTag.REDACT) await undoRange(item)
            dispatch(popUndoStack())
            dispatch(pushToRedoStack(item))
          })
          .catch((err) => console.error(err))
      }
      // else {
      //   Excel.run(async (ctx) => {
      //     const sheet = ctx.workbook.worksheets.getItemOrNullObject(
      //       item.reference.sheetId
      //     )
      //     sheet.load('isNullObject')
      //     await ctx.sync()
      //     if (sheet.isNullObject) return
      //     const range = sheet.getRange(item.reference.rangeAddr)
      //     const nano = nanoid()
      //     createBindingAndAddOnSelectionHandler(ctx, range, 'Range', nano)
      //     await dispatch(addReferece({ ...item.reference, bindingId: nano }))
      //     await setRangeValues(
      //       item.reference.sheetId,
      //       item.reference.rangeAddr,
      //       item.preValues
      //     )
      //     dispatch(popUndoStack())
      //     dispatch(
      //       pushToRedoStack({
      //         ...item,
      //         reference: { ...item.reference, bindingId: nano },
      //       })
      //     )
      //     await ctx.sync()
      //   }).catch((err) => console.error(err))
      // }
    }

    window.addEventListener(OfficeSimpleEvent.UNDO, handler)

    return () => window.removeEventListener(OfficeSimpleEvent.UNDO, handler)
  }, [stack, dispatch])
}

export default useUndo
