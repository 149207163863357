import { Dispatch } from 'react'
import { FileCommentTopic } from '../../types'
import { removeCommentById } from '../../slice/commentSlice'
import { useAppDispatch } from '../../dispatch'
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import dayjs from 'dayjs'

const TextArea = (
  ref: React.LegacyRef<HTMLInputElement>,
  comment: FileCommentTopic | undefined,
  dispatch: ReturnType<typeof useAppDispatch>,
  onClick: (...args: any[]) => any,
  userEmail: string,
  setPopup: Dispatch<React.SetStateAction<boolean>>
) => {
  const removeComments = () => {
    if (!comment) return
    return dispatch(removeCommentById(comment.id))
      .catch((err) => console.error(err))
      .finally(() => setPopup(false))
  }

  const onEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      const func = onClick as unknown as (...args: any) => void
      func(comment)
    }
  }

  return (
    <>
      <div className="relative w-full cursor-default overflow-hidden rounded-sm text-left shadow-md  focus:ring-2 focus:ring-teal-300/75 focus:ring-offset-2 focus:ring-offset-teal-300 sm:text-sm">
        {!comment && (
          <>
            <input
              ref={ref}
              className="w-full border-none rounded-sm  py-2 pl-3 pr-10 text-sm leading-5 text-gray-900"
              placeholder={'Comment here'}
              maxLength={140}
              onKeyDown={onEnterKeyDown}
            />
            <button
              className="absolute inset-y-0 right-0 items-center pr-2"
              onClick={() => onClick(comment)}
            >
              <ArrowCircleUpOutlinedIcon />
            </button>
          </>
        )}
        {comment && (
          <div className="flex flex-col p-2">
            <div className="overflow-y-auto max-h-60">
              <div className="flex justify-end">
                <button
                  className="hover:bg-gray-200 p-1"
                  onClick={removeComments}
                >
                  <DeleteOutlineOutlinedIcon />
                </button>
              </div>
              <div className="divide-x-2">
                <hr />
              </div>
              {[...comment.comments]
                .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
                .map((content, idx) => (
                  <div key={`${comment.id}-${idx}`} className="mt-2">
                    <div className="bg-white p-2 rounded-lg ">
                      <div className="flex mt-2">
                        <div className="relative flex items-center justify-center w-7 h-7 overflow-hidden bg-blue-200 rounded-full mr-1 ">
                          <span className="font-medium text-white">
                            {content.createdBy[0].toLocaleUpperCase()}
                          </span>
                        </div>
                        <div className="flex items-center mr-1">
                          <h3 className="text-md font-normal text-black">
                            {content.createdBy}
                          </h3>
                        </div>
                        <div className="flex items-center">
                          <h4 className="text-md font-light">
                            {dayjs(content.createdAt).format('DD/MM/YYYY')}
                          </h4>
                        </div>
                        {/* <button
                          className="hover:bg-gray-200 p-2"
                          onClick={() => removeComment(comment.id)}
                        >
                          <FaRegTrashAlt size={20} />
                        </button> */}
                      </div>
                      <div className="flex mt-2">
                        <p className="text-black text-base indent-1">
                          {content.content}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex mt-2 items-center">
              <div className="relative flex items-center justify-center w-7 h-7 overflow-hidden bg-blue-200 rounded-full mr-1 ">
                <span className="font-medium text-white">{userEmail}</span>
              </div>
              <div className="relative flex w-full">
                <input
                  ref={ref}
                  className="w-full border-none rounded-sm  py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 bg-gray-200"
                  placeholder={'Reply here'}
                  maxLength={140}
                  onKeyDown={onEnterKeyDown}
                />
                <button
                  className="absolute inset-y-0 right-0 items-center pr-2"
                  onClick={() => onClick(comment)}
                >
                  <ArrowCircleUpOutlinedIcon />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TextArea
