import { useState } from 'react'
import ModalContext from './FormModalContext'
import Modal from './FormModal'
import {
  OpenFormModalOptions,
  PromiseInfo,
  ShowFormModalHandler,
  ShowFormModalResponse,
} from './types'

const FormModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [show, setShow] = useState(false)
  const [options, setOptions] = useState<OpenFormModalOptions>({
    title: <></>,
    content: <></>,
  })
  const [promiseInfo, setPromiseInfo] =
    useState<PromiseInfo<ShowFormModalResponse>>()

  const showFormModal: ShowFormModalHandler = (options) => {
    // When the dialog is shown, keep the promise info so we can resolve later
    return new Promise((resolve, reject) => {
      setPromiseInfo({ resolve, reject })
      setOptions(options)
      setShow(true)
    })
  }

  return (
    <ModalContext.Provider value={{ showFormModal }}>
      {children}
      <Modal
        options={options}
        show={show}
        setShow={setShow}
        promiseInfo={promiseInfo}
      />
    </ModalContext.Provider>
  )
}

export default FormModalProvider
