import React from 'react'

type Props = {
  title: string
  children: React.ReactNode
}

const Tooltip: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="group relative flex justify-center">
      {children}
      <div className="absolute top-10 w-max scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover:scale-100 before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1] before:-top-1 before:left-0  before:right-0 before:mx-auto">
        {title}
      </div>
    </div>
  )
}

export default Tooltip
