// import { Page, Document, pdfjs } from 'react-pdf'
import {
  CanvasArea,
  ExtractionMethod,
  FileRotation,
  RotationType,
  TestDataOcr,
} from '../../types'
import { Dispatch, RefObject, useMemo, useRef, useState } from 'react'
import { DocumentCallback, PageCallback } from 'react-pdf/dist/cjs/shared/types'
import KonvaContainer from '../KonvaContainer'
import { BeatLoader } from 'react-spinners'
import ErrorMsg from '../ErrorMsg'
import { GENERAL_ERR_CONTENT, LOAD_FILE_ERR_TITLE } from '../../constant'
import { useNavigate } from 'react-router-dom'
import PdfViewer from '../PdfViewer'
import { ReaderAPI, RenderKonvaComponent } from '../PdfViewer/types'
import { toast } from 'react-toastify'
import { useCutEvent } from '../../hooks/useCutEvent'
import useControl from '../../hooks/useControl'

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url
// ).toString()

// const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
// pdfjs.GlobalWorkerOptions.workerSrc = url

type Props = {
  file: string | Blob
  page: number
  divRef: RefObject<HTMLDivElement>
  setTotalPages: Dispatch<React.SetStateAction<number>>
  displayKonva: boolean
  setDisplayKonva: Dispatch<React.SetStateAction<boolean>>
  setCurrentPage: Dispatch<React.SetStateAction<number>>
  // ocrUrl: string
  fileId: string
  extractionMethod: ExtractionMethod | undefined
  rotationDegree: RotationType
  dispatchRotation: Dispatch<{
    type: string
    payload: undefined | number
  }>
  ocr: TestDataOcr | undefined
  setShowConfirmRedact: Dispatch<React.SetStateAction<boolean>>
  setPageLoading: Dispatch<React.SetStateAction<boolean>>
  searchAllItem: string[]
  totalPages: number
  navigate: ReturnType<typeof useNavigate>
  setReaderAPI?: (readerAPI: ReaderAPI) => void
}

const renderLoader = () => (
  <div className="flex flex-col h-screen w-screen p-2 items-center justify-center">
    <BeatLoader color="#36d7b7" />
  </div>
)

const renderErrorMsg = (
  title: string,
  content: string,
  navigate: ReturnType<typeof useNavigate>
) => (
  <div className="flex w-full p-4">
    <ErrorMsg title={title} content={content} navigate={navigate} />
  </div>
)

const PDFDisplaySection = ({
  file,
  divRef,
  setTotalPages,
  setCurrentPage,
  displayKonva,
  setDisplayKonva,
  // ocrUrl,
  fileId,
  rotationDegree,
  extractionMethod,
  dispatchRotation,
  ocr,
  setShowConfirmRedact,
  setPageLoading,
  searchAllItem,
  navigate,
  setReaderAPI,
  page,
}: Props) => {
  const opt = useMemo(() => {
    return {
      cMapUrl: '/bcmaps/',
      cMapPacked: true,
    }
  }, [])
  // const width = useFileResize()
  const [canvasArea, setCanvasArea] = useState<CanvasArea>({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  // const [pages, setPages] = useState<Map<string, TextBlock>>(new Map())
  // const [tables, setTables] = useState<Map<string, TextBlock>>(new Map())
  // const [cells, setCells] = useState<Map<string, TextBlock>>(new Map())
  // const [lines, setLines] = useState<Map<string, TextBlock>>(new Map())
  // const [words, setWords] = useState<Map<string, TextBlock>>(new Map())

  const pageRef = useRef<HTMLCanvasElement>(null)
  const isHoldingCtrl = useControl()

  useCutEvent(toast)

  // useEffect(() => {
  //   const [p, t, c, l, w] = breakDownOcr(ocr, rotationDegree.curr)
  //   setPages(p)
  //   setTables(t)
  //   setCells(c)
  //   setLines(l)
  //   setWords(w)
  // }, [ocr, rotationDegree])

  const onPageLoaded = async (pageCallback: PageCallback) => {
    dispatchRotation({
      payload: pageCallback.rotate,
      type: FileRotation.SET_META,
    })
    divRef.current?.scrollIntoView({ behavior: 'smooth' })
    setDisplayKonva(true)
    if (pageRef.current) {
      const clientRects = pageRef.current.getBoundingClientRect()
      setCanvasArea({
        height: clientRects.height,
        width: clientRects.width,
      })
    }
  }
  const onDocumentLoaded = (document: DocumentCallback) => {
    setTotalPages(document.numPages)
  }

  const renderKonvaComponent: RenderKonvaComponent = (props) => {
    const {
      pageIndex,
      width,
      height,
      style,
      className,
      allowToast,
      cells,
      lines,
      pages,
      setCells,
      setTables,
      tables,
      words,
    } = props
    return (
      displayKonva &&
      ocr && (
        <KonvaContainer
          canvasArea={canvasArea}
          width={width}
          height={height}
          pages={pages}
          cells={cells}
          lines={lines}
          tables={tables}
          words={words}
          filePage={pageIndex + 1}
          fileId={fileId}
          extractionMethod={extractionMethod}
          rotationDegree={rotationDegree}
          setShowConfirmRedact={setShowConfirmRedact}
          setPageLoading={setPageLoading}
          searchAllItem={searchAllItem}
          setTables={setTables}
          setCells={setCells}
          className={className}
          style={style}
          allowToast={allowToast ?? false}
          isHoldingCtrl={isHoldingCtrl}
        />
      )
    )
  }

  return (
    <div
      className="flex relative w-full"
      style={{ height: '100vh' }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <PdfViewer
        file={file}
        setReaderAPI={setReaderAPI}
        loading={renderLoader()}
        onPageChange={({ currentPage }) => setCurrentPage(currentPage)}
        onDocumentLoad={onDocumentLoaded}
        reactPDFDocumentProps={{
          onLoadError: () => console.log('onLoadError'),
          options: opt,
          error: renderErrorMsg(
            LOAD_FILE_ERR_TITLE,
            GENERAL_ERR_CONTENT,
            navigate
          ),
        }}
        onPageLoadSuccess={onPageLoaded}
        onPageLoadError={() => console.log('onLoadError')}
        renderKonvaComponent={renderKonvaComponent}
        ocr={ocr}
        rotationDegree={rotationDegree}
      ></PdfViewer>

      {/* @TODO: remove this code */}
      {/* <Document
        className={'px-4'}
        file={file}
        onLoadSuccess={onDocumentLoaded}
        onLoadError={() => console.log('onLoadError')}
        options={opt}
        loading={renderLoader()}
        error={renderErrorMsg(
          LOAD_FILE_ERR_TITLE,
          GENERAL_ERR_CONTENT,
          navigate
        )}
      >
        {page <= totalPages && (
          <Page
            pageNumber={page}
            renderAnnotationLayer={false}
            renderForms={false}
            renderTextLayer={false}
            width={width}
            onLoadSuccess={onPageLoaded}
            canvasRef={pageRef}
            rotate={(rotationDegree.curr + rotationDegree.meta) % 360}
            onRenderError={() => console.log('onRenderError')}
          />
        )}
      </Document>
      {displayKonva && ocr && (
        <KonvaContainer
          canvasArea={canvasArea}
          width={canvasArea.width}
          height={canvasArea.height}
          pages={pages}
          cells={cells}
          lines={lines}
          tables={tables}
          words={words}
          filePage={page}
          fileId={fileId}
          extractionMethod={extractionMethod}
          rotationDegree={rotationDegree}
          setShowConfirmRedact={setShowConfirmRedact}
          setPageLoading={setPageLoading}
          searchAllItem={searchAllItem}
          setTables={setTables}
          setCells={setCells}
        />
      )} */}
    </div>
  )
}

export default PDFDisplaySection
