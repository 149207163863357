import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

type SaveToLocalState = {
  isLocalMode: boolean
}

const initialState: SaveToLocalState = {
  isLocalMode: false,
}

export const initSaveToLocal = createAsyncThunk(
  'SaveToLocal/initSaveToLocal',
  () =>
    Excel.run(async (ctx) => {
      const settings = ctx.workbook.settings
      const item = settings.getItemOrNullObject('IsLocalMode')
      item.load(['isNullObject', 'value'])
      await ctx.sync()
      let obj: SaveToLocalState = { isLocalMode: false }
      if (item.isNullObject) {
        settings.add('IsLocalMode', JSON.stringify(obj))
      } else {
        obj = JSON.parse(item.value)
      }
      // settings.add('IsLocalMode', JSON.stringify(obj))
      await ctx.sync()
      return obj
    })
)

export const setLocalMode = createAsyncThunk('SaveToLocal/setLocalMode', () =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const obj: SaveToLocalState = { isLocalMode: true }
    settings.add('IsLocalMode', JSON.stringify(obj))
    await ctx.sync()
    return obj
  })
)

export const setCloudMode = createAsyncThunk('SaveToLocal/setCloudMode', () =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const obj: SaveToLocalState = { isLocalMode: false }
    settings.add('IsLocalMode', JSON.stringify(obj))
    await ctx.sync()
    return obj
  })
)

const saveToLocalSlice = createSlice({
  name: 'SaveToLocal',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(initSaveToLocal.fulfilled, (state, action) => {
      state.isLocalMode = action.payload.isLocalMode
    })
    builder.addCase(setLocalMode.fulfilled, (state) => {
      state.isLocalMode = true
    })
    builder.addCase(setCloudMode.fulfilled, (state) => {
      state.isLocalMode = false
    })
  },
})

export default saveToLocalSlice.reducer
