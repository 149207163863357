import { useState } from 'react'
import { DynamoFile, FileStatus, FolderItem } from '../types'
import { useQuery } from 'react-query'
import { listAllFilesV2 } from '../api'
import dayjs from 'dayjs'

const useFiles = () => {
  const [folderStack, setFolderStack] = useState<FolderItem[]>([])
  const [files, setFiles] = useState<DynamoFile[]>([])
  const { isError, refetch, isFetching, isLoading, error, isSuccess } =
    useQuery('ALL_FILES', listAllFilesV2, {
      refetchInterval: (data) => {
        const result = data?.find(
          (data) =>
            data.status === FileStatus.PROCESSING ||
            data.status === FileStatus.UPLOADING ||
            data.status === FileStatus.REDACTING
        )
        return result ? 3000 : false
      },
      onSuccess: (success) =>
        setFiles(success.sort((a, b) => dayjs(b.createdAt).diff(a.createdAt))),
      refetchOnWindowFocus: false,
    })

  return {
    files,
    isError,
    refetch,
    setFiles,
    isFetching,
    isLoading,
    folderStack,
    setFolderStack,
    error,
    isSuccess,
  }
}

export default useFiles
