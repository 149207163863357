import { Listbox, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

type Props = {
  data: string[]
  index: number
  selected: string
  setSelected: (val: string) => void
}

const DataMatchOutputDropdown = ({
  data,
  index,
  selected,
  setSelected,
}: Props) => {
  return (
    <div>
      <Listbox value={selected} onChange={setSelected}>
        <Listbox.Button
          className={
            'transition ease-in-out delay-75 hover:scale-105 w-full text-start p-2'
          }
        >
          {selected}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={
              'absolute bg-white flex flex-col gap-2 h-48 w-32 overflow-y-auto z-10'
            }
          >
            {data.map((d) => (
              <Listbox.Option
                key={d}
                value={d}
                className={({ active }) =>
                  `relative cursor-default select-none p-2 ${
                    active ? 'bg-gray-200 text-black' : 'text-gray-900'
                  }`
                }
              >
                {({ selected }) => (
                  <div className="flex items-center">
                    {selected ? (
                      <span className="flex items-center text-green-400 mr-1">
                        <CheckOutlinedIcon sx={{ fontSize: 18 }} />
                      </span>
                    ) : null}
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {d}
                    </span>
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  )
}

export default DataMatchOutputDropdown
