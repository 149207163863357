import { Group, Layer, Rect } from 'react-konva'
import { Cut, KonvaRectangle } from '../../types'
import numeral from 'numeral'
import { Html } from 'react-konva-utils'
import TableDropdown from '../CustomDropdown/TableDropdown'

type Props = {
  tableRects: KonvaRectangle[]
  cellRects: KonvaRectangle[]
  ratioX: numeral.Numeral
  ratioY: numeral.Numeral
  onTableClick: (tableId: string, type: Cut) => void
  removeTableAndCells: (table: string) => void
  extractAllSystemTables: () => Promise<void>
  totalFileTables: number
}

const TableCellLayer = ({
  cellRects,
  ratioX,
  ratioY,
  tableRects,
  onTableClick,
  removeTableAndCells,
  extractAllSystemTables,
  totalFileTables,
}: Props) => {
  return (
    <Layer>
      {cellRects.map((r) => (
        <Rect
          key={r.ocrId}
          x={numeral(r.x).divide(ratioX.value()).value() ?? -1}
          y={numeral(r.y).divide(ratioY.value()).value() ?? -1}
          width={numeral(r.w).divide(ratioX.value()).value() ?? -1}
          height={numeral(r.h).divide(ratioY.value()).value() ?? -1}
          stroke={r.stroke}
        />
      ))}
      {tableRects.reverse().map((r) => {
        const x = numeral(r.x).divide(ratioX.value()).value() ?? -1
        const y = numeral(r.y).divide(ratioY.value()).value() ?? -1
        const width = numeral(r.w).divide(ratioX.value()).value() ?? -1
        const height = numeral(r.h).divide(ratioY.value()).value() ?? -1
        return (
          <Group key={r.ocrId}>
            <Html
              transformFunc={(props) => {
                return {
                  ...props,
                  x: x + width + 2,
                  y,
                }
              }}
            >
              <TableDropdown
                bindingId={r.ocrId}
                snipTable={onTableClick}
                removeSystemTable={removeTableAndCells}
                extractAllSystemTables={extractAllSystemTables}
                tableRects={totalFileTables}
              />
              {/* <div className="flex flex-col gap-2">
                <button
                  className={`bg-gray-100/50 hover:bg-gray-200/50 rounded `}
                  onClick={onTableClick}
                  name={r.ocrId}
                >
                  {cutType === Cut.TABLES ? (
                    <TableViewOutlinedIcon sx={{ fontSize: 16 }} />
                  ) : (
                    <ColorizeOutlinedIcon sx={{ fontSize: 16 }} />
                  )}
                </button>
                {cutType === Cut.TABLECUT && (
                  <button
                    className={`bg-red-100/50 hover:bg-red-200/50 rounded`}
                    onClick={() => removeTableAndCells(r.ocrId)}
                    name={r.ocrId}
                  >
                    <CloseOutlinedIcon sx={{ fontSize: 16 }} />
                  </button>
                )}
              </div> */}
            </Html>
            <Rect
              x={x}
              y={y}
              width={width}
              height={height}
              stroke={r.stroke}
              name={r.ocrId}
            />
          </Group>
        )
      })}
    </Layer>
  )
}

export default TableCellLayer
