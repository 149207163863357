import { BaseMetricsClient } from '../client';
import { BrowserTransport } from './transport';
export class BrowserMetricsClient extends BaseMetricsClient {
    endpoint;
    constructor(config) {
        const { endpoint, headers, ...options } = config;
        const transport = options.transport || new BrowserTransport(endpoint, headers);
        super(transport, options);
        this.endpoint = endpoint;
    }
    async getAggregatedMetrics(input) {
        const response = await fetch(`${this.endpoint}/aggregate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),
        });
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status} ${response.statusText}`);
        }
        return response.json();
    }
}
