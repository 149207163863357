import { Group, Line, Rect } from 'react-konva'
import { Html } from 'react-konva-utils'
import {
  CutTag,
  EditTableFn,
  ExtractionMethod,
  TextBlock,
  TextcutRectangle,
  UserGeneratedLine,
} from '../../types'
import { getWordsInsideRect, isVertical } from '../../utils/spatial'
import 'react-toastify/dist/ReactToastify.css'
import useFormModal from '../FormModal/useFormModal'
import TableOptionsModalContent from '../TableOptions/TableOptionsModalContent'
import useTableOptionsProcess from '../TableOptions/useTableOptionsProcess'
import {
  CustomWord,
  TableOptionsModalResponseData,
} from '../TableOptions/types'
import TableDropdown from '../CustomDropdown/TableDropdown'
import RemoveLineIconButton from './RemoveLineIconButton'
import { useAppDispatch } from '../../dispatch'

type Props = {
  bindingId: string
  x: number
  y: number
  w: number
  h: number
  fill: string
  stroke: string
  ruling: null | JSX.Element
  changeRulingDirection: (bindingId: string, value: 0 | 1 | 2) => void
  rulingDirection: 0 | 1 | 2
  lines?: UserGeneratedLine[]
  removeLine: (id: string, lineId: string) => void
  tag: CutTag
  textCutRect: TextcutRectangle
  editTable: EditTableFn
  wordsBlocks: TextBlock[]
  extractionMethod: ExtractionMethod | undefined
  dispatch: ReturnType<typeof useAppDispatch>
}

const RectGroup = ({
  h,
  w,
  x,
  y,
  fill,
  stroke,
  bindingId,
  ruling,
  changeRulingDirection,
  rulingDirection,
  lines,
  removeLine,
  tag,
  textCutRect,
  editTable,
  wordsBlocks,
  extractionMethod,
  dispatch,
}: Props) => {
  const { showFormModal } = useFormModal()
  const { updateTextcutRect } = useTableOptionsProcess()

  const handleTableOptionsClick = async () => {
    const rect = {
      x: textCutRect.x,
      y: textCutRect.y,
      w: textCutRect.w,
      h: textCutRect.h,
    }

    const words: CustomWord[] = getWordsInsideRect(rect, wordsBlocks).map(
      (block) => {
        const x1 = block.boundingPoly.vertices[0].x
        const y1 = block.boundingPoly.vertices[0].y
        const x2 = block.boundingPoly.vertices[2].x
        const y2 = block.boundingPoly.vertices[2].y

        const word: CustomWord = {
          id: block.id,
          text: block.description,
          coords: [x1, y1, x2, y2],
        }

        return word
      }
    )

    const res = await showFormModal<TableOptionsModalResponseData>({
      title: 'Advanced table formatting',
      content: (
        <TableOptionsModalContent
          textCutRect={textCutRect}
        ></TableOptionsModalContent>
      ),
      submitText: 'Apply',
    })

    if (res.success && res.data) {
      const newTextcutRect = updateTextcutRect(
        textCutRect,
        res.data,
        words,
        extractionMethod
      )
      editTable(newTextcutRect, newTextcutRect.lines!, true)
    }
  }
  return (
    <Group>
      <Rect
        x={x}
        y={y}
        width={w}
        height={h}
        fill={fill}
        stroke={stroke}
        strokeWidth={3}
        name={bindingId}
      />
      {tag === CutTag.TABLE && (
        <>
          <Html
            transformFunc={(props) => {
              return {
                ...props,
                x: x + w + 2,
                y,
              }
            }}
          >
            <TableDropdown
              bindingId={bindingId}
              changeRulingDirection={changeRulingDirection}
              dispatch={dispatch}
              handleTableOptionsClick={handleTableOptionsClick}
              rulingDirection={rulingDirection}
            />
            {/* <button
              className={`bg-gray-100/50`}
              onClick={() => changeRulingDirection(bindingId)}
            >
              {rulingDirection ? (
                <MoreHorizOutlinedIcon sx={{ fontSize: 16 }} />
              ) : (
                <MoreVertOutlinedIcon sx={{ fontSize: 16 }} />
              )}
            </button> */}
          </Html>
          {/* <Html
            transformFunc={(props) => {
              return {
                ...props,
                x: x + w + 2,
                y: y + 24,
              }
            }}
          >
            <button
              className={`bg-gray-100/50`}
              onClick={handleTableOptionsClick}
            >
              <TuneOutlinedIcon sx={{ fontSize: 16 }} />
            </button>
          </Html> */}
          {rulingDirection !== 0 && ruling}
          {lines &&
            lines.length > 0 &&
            lines.map((line) => (
              <Group key={line.id}>
                <Line points={line.line} stroke={stroke} strokeWidth={2} />
                <Html
                  divProps={{ style: { width: 16, height: 16, zIndex: 1 } }}
                  transformFunc={(props) => {
                    const verticalLine = isVertical(line.line)
                    return {
                      ...props,
                      x: line.line.at(0)! + (verticalLine ? -7 : -9),
                      y: line.line.at(3)! - (verticalLine ? 12 : 14),
                    }
                  }}
                >
                  {/* <RemoveOutlinedIcon
                    className="bg-red-200/50 rounded-xl opacity-50 transition ease-in-out  hover:opacity-100 hover:scale-105"
                    sx={{
                      fontSize: 14,
                      border: 1,
                      cursor: 'pointer',
                    }}
                    onClick={() => removeLine(bindingId, line.id)}
                  /> */}
                  {rulingDirection === 0 && <></>}
                  {rulingDirection === 1 && !isVertical(line.line) && (
                    <RemoveLineIconButton
                      bindingId={bindingId}
                      lineId={line.id}
                      removeLine={removeLine}
                    />
                  )}
                  {rulingDirection === 2 && isVertical(line.line) && (
                    <RemoveLineIconButton
                      bindingId={bindingId}
                      lineId={line.id}
                      removeLine={removeLine}
                    />
                  )}
                </Html>
              </Group>
            ))}
        </>
      )}
    </Group>
  )
}

export default RectGroup
