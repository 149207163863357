import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Dispatch } from 'react'
import {
  DynamoFile,
  FileCommentTopic,
  FileStatus,
  FolderItem,
  TextcutRectangle,
} from '../../types'
import { PopupTextType } from '../Popup'
import numeral from 'numeral'
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import { fileContainsComment, fileSizeLessThan5MB } from '../../utils/common'
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import { ClipLoader } from 'react-spinners'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'

const DELETE_FILE = {
  header: 'Delete File',
  content: 'Are you sure to delete the file?',
}

type Props = {
  files: DynamoFile[]
  onFileClick: (file: DynamoFile) => () => Promise<void>
  showPopup: (popup: PopupTextType) => void
  deletePDF: (fileId: string) => Promise<void>
  isTrimmable: (fileId: string) => any
  trimFile: (fileId: string) => void
  isExportable: (fileId: string) => boolean
  exportPDF: (file: DynamoFile) => () => Promise<void>
  downloadFile: (file: DynamoFile) => () => Promise<void>
  folders?: FolderItem[]
  folderStack?: FolderItem[]
  setFolderStack?: Dispatch<React.SetStateAction<FolderItem[]>>
  references: TextcutRectangle[]
  comments: FileCommentTopic[]
  deleteFolder?: (folderItem: FolderItem) => void
  movable?: boolean
  move?: ({
    id,
    name,
    type,
  }: {
    id: string
    name: string
    type: 'folder' | 'file'
  }) => void
  savePdfsToWorkbook: (file: DynamoFile) => Promise<void>
  showRenamePopup: (fileId: string) => void
}

type FilePathBreadcrumProps = {
  setFolderStack: Dispatch<React.SetStateAction<FolderItem[]>>
  folderStack: FolderItem[]
}

const renderIndicator = (fileStatus: string) => {
  if (fileStatus === FileStatus.SUCCEEDED)
    return (
      <div className="w-4 h-4 bg-green-200 rounded-full border-2 border-green-600 mr-2"></div>
    )
  else if (isUnhealthyFile(fileStatus))
    return (
      <div className="w-4 h-4  border-red-600 border-2 rounded-full mr-2"></div>
    )
  else
    return (
      <div className="mr-2">
        <ClipLoader color="#FBBF24" size={14} />
      </div>
    )
}

const renderFilePathBreadcrum = ({
  folderStack,
  setFolderStack,
}: FilePathBreadcrumProps) => {
  const stack = [
    { id: null as any, name: 'Files' } as FolderItem,
    ...folderStack,
  ]
  const onPathClick = (idx: number) => () => {
    const tmp = stack.slice(0, idx + 1)
    tmp.shift()
    setFolderStack(tmp)
  }

  return (
    <ol className="inline-flex gap-2">
      {stack.map((path, i) => (
        <li key={i}>
          <div className="flex items-center gap-2 ">
            <div
              className={`text-xl cursor-pointer font-semibold  ${
                i === stack.length - 1 ? 'text-black' : 'text-gray-400'
              }`}
              onClick={onPathClick(i)}
            >
              {path ? path.name : 'Files'}
            </div>
            {i < stack.length - 1 && (
              <div className="flex items-center text-xl font-semibold text-gray-300">
                {'>'}
              </div>
            )}
          </div>
        </li>
      ))}
    </ol>
  )
}

const isUnhealthyFile = (status: string) =>
  status === FileStatus.FAILED ||
  status === FileStatus.INVALID ||
  status === FileStatus.MALICIOUS

const renderUnhealthyFileBadge = (status: string) => {
  switch (status) {
    case FileStatus.FAILED:
      return 'Failed'
    case FileStatus.INVALID:
      return 'Invalid'
    case FileStatus.MALICIOUS:
      return 'Malicious'
    default:
      return 'Something wrong'
  }
}

const MobileFileList = ({
  files,
  comments,
  deletePDF,
  downloadFile,
  exportPDF,
  isExportable,
  isTrimmable,
  onFileClick,
  savePdfsToWorkbook,
  showPopup,
  trimFile,
  deleteFolder,
  folderStack,
  folders,
  movable,
  move = () => {},
  setFolderStack,
  showRenamePopup,
}: Props) => {
  return (
    <ul>
      {folderStack && folderStack?.length !== 0 && setFolderStack && (
        <li className="relative rounded-md p-1 py-3">
          {renderFilePathBreadcrum({ folderStack, setFolderStack })}
        </li>
      )}
      {folders &&
        folders.length > 0 &&
        folders.map((folder, i) => (
          <li
            className="relative rounded-md p-1 hover:bg-gray-100"
            key={folder.id}
          >
            <div className="">
              <div className="flex justify-between items-center h-11">
                <div
                  className="flex w-full h-full items-center cursor-pointer"
                  onClick={() =>
                    setFolderStack
                      ? setFolderStack([...(folderStack ?? []), folder])
                      : null
                  }
                >
                  <div className="flex items-center mr-2">
                    <FolderOutlinedIcon sx={{ fontSize: 20 }} />
                  </div>

                  <div className="flex items-center">
                    <h3 className="text-sm font-medium leading-5">
                      {folder.name}
                    </h3>
                  </div>
                </div>
                {movable && (
                  <div className="flex p-2.5   justify-center items-end hover:bg-gray-400 rounded">
                    <Menu>
                      <MenuButton>
                        <MoreHorizOutlinedIcon sx={{ fontSize: 20 }} />
                      </MenuButton>
                      <MenuItems
                        anchor="bottom start"
                        className="z-50 flex flex-col  bg-white/90 w-40 border-gray-200 rounded border gap-2"
                      >
                        <MenuItem>
                          <button
                            className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                            onClick={() =>
                              move({
                                id: folder.id,
                                name: folder.name,
                                type: 'folder',
                              })
                            }
                          >
                            <div>
                              <DriveFileMoveOutlinedIcon
                                sx={{ fontSize: 20, zIndex: 0 }}
                              />
                            </div>
                            <div className="flex-grow">Move</div>
                          </button>
                        </MenuItem>
                        <MenuItem>
                          <button
                            className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                            onClick={() =>
                              deleteFolder ? deleteFolder(folder) : null
                            }
                          >
                            <div>
                              <DeleteOutlineOutlinedIcon
                                sx={{ fontSize: 20, zIndex: 0 }}
                              />
                            </div>
                            <div className="flex-grow">Delete</div>
                          </button>
                        </MenuItem>

                        <MenuItem>
                          <button
                            className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                            onClick={() => showRenamePopup(folder.id)}
                          >
                            <div>
                              <DriveFileRenameOutlineOutlinedIcon
                                sx={{ fontSize: 20 }}
                              />
                            </div>
                            <div className="flex-grow">Rename</div>
                          </button>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      {files.length > 0 &&
        files.map((file, i) => (
          <li key={file.fileId} className={`rounded-md p-1 hover:bg-gray-100`}>
            <div className="">
              <div className="flex justify-between items-center h-11">
                <div
                  className="flex w-full h-full justify-start items-center cursor-pointer"
                  onClick={onFileClick(file)}
                >
                  {renderIndicator(file.status)}
                  <div className="mr-2">
                    <h3 className="text-sm font-medium leading-5">
                      {file.fileName.length >= 20
                        ? `${file.fileName.slice(0, 17)}...`
                        : file.fileName}
                    </h3>
                  </div>
                  {!isUnhealthyFile(file.status) && (
                    <div className="text-xs font-normal leading-4 text-gray-500 mr-2">
                      {numeral(file.originalFileSize).format('0b').toString()}
                    </div>
                  )}
                  {!file.isLocal && (
                    <div className="flex mr-2 items-center">
                      <CloudOutlinedIcon sx={{ fontSize: 12 }} />
                    </div>
                  )}
                  {!isUnhealthyFile(file.status) &&
                    fileContainsComment(file, comments) && (
                      <div className="flex mr-2 items-center">
                        <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 12 }} />
                      </div>
                    )}
                  {isUnhealthyFile(file.status) && (
                    <div className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                      {renderUnhealthyFileBadge(file.status)}
                    </div>
                  )}
                </div>
                {file.status !== FileStatus.SUCCEEDED &&
                  file.status !== FileStatus.UPLOADING &&
                  file.status !== FileStatus.PROCESSING && (
                    <div className="group/singledeletefile flex justify-center rounded  hover:bg-gray-500">
                      <button
                        className="w-full h-full p-2.5 rounded transition ease-in-out delay-100 hover:scale-110"
                        onClick={() =>
                          showPopup({
                            ...DELETE_FILE,
                            onClick: () => deletePDF(file.fileId),
                          })
                        }
                      >
                        <DeleteOutlineOutlinedIcon sx={{ fontSize: 20 }} />
                      </button>
                      <div
                        className={`absolute ${
                          i === files.length - 1
                            ? files.length === 1
                              ? 'bottom-8 before:-bottom-1'
                              : 'bottom-14 before:-bottom-1'
                            : 'top-14 before:-top-1'
                        }
                          scale-0 rounded bg-neutral-700 p-2 text-xs text-white group-hover/singledeletefile:scale-100 group-hover/singledeletefile:w-fit before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-1  before:right-0 before:mx-auto`}
                      >
                        Delete
                      </div>
                    </div>
                  )}
                {file.status === FileStatus.SUCCEEDED && (
                  <div className="flex p-2.5   justify-center items-end hover:bg-gray-400 rounded">
                    <Menu>
                      <MenuButton>
                        <MoreHorizOutlinedIcon sx={{ fontSize: 20 }} />
                      </MenuButton>
                      <MenuItems
                        anchor="bottom start"
                        className="z-50 flex flex-col  bg-white/90 w-48 border-gray-200 rounded border gap-2"
                      >
                        <MenuItem>
                          <button
                            className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                            onClick={() =>
                              showPopup({
                                ...DELETE_FILE,
                                onClick: () => deletePDF(file.fileId),
                              })
                            }
                          >
                            <div>
                              <DeleteOutlineOutlinedIcon
                                sx={{ fontSize: 20 }}
                              />
                            </div>
                            <div className="flex-grow">Delete file</div>
                          </button>
                        </MenuItem>

                        {file.status === FileStatus.SUCCEEDED && (
                          <MenuItem>
                            <button
                              className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                              onClick={() =>
                                move({
                                  id: file.fileId,
                                  name: file.fileName,
                                  type: 'file',
                                })
                              }
                            >
                              <div>
                                <DriveFileMoveOutlinedIcon
                                  sx={{ fontSize: 20 }}
                                />
                              </div>
                              <div className="flex-grow">Move</div>
                            </button>
                          </MenuItem>
                        )}

                        {!file.isLocal &&
                          fileSizeLessThan5MB(file.originalFileSize) && (
                            <MenuItem>
                              <button
                                className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                                onClick={() => savePdfsToWorkbook(file)}
                              >
                                <div>
                                  <CloudDownloadOutlinedIcon
                                    sx={{ fontSize: 20 }}
                                  />
                                </div>
                                <div className="flex-grow">
                                  Save file to local
                                </div>
                              </button>
                            </MenuItem>
                          )}

                        {isTrimmable(file.fileId) && (
                          <MenuItem>
                            <button
                              className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                              onClick={() => trimFile(file.fileId)}
                            >
                              <div>
                                <ContentCutOutlinedIcon sx={{ fontSize: 20 }} />
                              </div>
                              <div className="flex-grow">
                                Delete pages without links
                              </div>
                            </button>
                          </MenuItem>
                        )}

                        {isExportable(file.fileId) && (
                          <MenuItem>
                            <button
                              className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                              onClick={exportPDF(file)}
                            >
                              <div>
                                <SimCardDownloadOutlinedIcon
                                  sx={{ fontSize: 20 }}
                                />
                              </div>
                              <div className="flex-grow">
                                Export modified file
                              </div>
                            </button>
                          </MenuItem>
                        )}

                        <MenuItem>
                          <button
                            className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                            onClick={downloadFile(file)}
                          >
                            <div>
                              <FileUploadOutlinedIcon sx={{ fontSize: 20 }} />
                            </div>
                            <div className="flex-grow">
                              Export original file
                            </div>
                          </button>
                        </MenuItem>

                        <MenuItem>
                          <button
                            className="flex gap-2 items-center justify-around hover:bg-gray-100 p-2 text-gray-800 font-semibold"
                            onClick={() => showRenamePopup(file.fileId)}
                          >
                            <div>
                              <DriveFileRenameOutlineOutlinedIcon
                                sx={{ fontSize: 20 }}
                              />
                            </div>
                            <div className="flex-grow">Rename</div>
                          </button>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
    </ul>
  )
}

export default MobileFileList
