import { PdfZoomInZoomOutEvent } from '../../types'

const publishZoomInZoomOutEvent = () => {
  const event = new CustomEvent(PdfZoomInZoomOutEvent.ZOOM_IN_ZOMM_OUT_EVENT, {
    detail: null,
  })
  window.dispatchEvent(event)
}

const ZOOM_LEVELS = [
  50, 70, 80, 90, 100, 110, 120, 130, 150, 170, 200, 300, 400,
].map((level) => level / 100)
const useZoom = ({
  scale,
  defaultScale,
  setScale,
}: {
  scale: number | undefined
  defaultScale: number | null
  setScale: (newScale: number) => void
}) => {
  const increaseZoom = () => {
    if (!scale) return
    const currentIndex = ZOOM_LEVELS.findIndex((level) => level >= scale)
    const nextIndex =
      currentIndex + 1 < ZOOM_LEVELS.length ? currentIndex + 1 : currentIndex
    setScale(ZOOM_LEVELS[nextIndex])
    publishZoomInZoomOutEvent()
  }

  const decreaseZoom = () => {
    if (!scale) return
    const currentIndex = ZOOM_LEVELS.findIndex((level) => level >= scale)
    const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : 0
    setScale(ZOOM_LEVELS[prevIndex])
    publishZoomInZoomOutEvent()
  }

  const zoomFitWidth = () => {
    if (!defaultScale) return
    setScale(defaultScale)
    publishZoomInZoomOutEvent()
  }

  return { increaseZoom, decreaseZoom, zoomFitWidth }
}

export default useZoom
