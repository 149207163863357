import { z } from 'zod';
const MetricNamespaceEnum = z.enum([
    'ExcelAddin/TextCut',
    'ExcelAddin/TableCut',
    'ExcelAddin/Redact',
    'ExcelAddin/Sum',
    'ExcelAddin/DataMatch',
    'ExcelAddin/EditTable',
    'ExcelAddin/Search',
    'Service/PdfProcessor',
    'Service/UserFileDelete',
    'Service/UserFileDeleteExpired',
]);
const MetricNameEnum = z.enum([
    'Count',
    'Duration',
    'SuccessCount',
    'PageCount',
    'FailedCount',
    'MaliciousCount',
    'InvalidCount',
]);
export const metricIngestSchema = z.object({
    firm_id: z.string(),
    namespace: MetricNamespaceEnum,
    metric_name: MetricNameEnum,
    metric_value: z.number(),
    timestamp: z
        .string()
        .refine((val) => !isNaN(Date.parse(val)), {
        message: 'Invalid date format',
    })
        .optional(),
    user_id: z.string().optional(),
    additional_data: z.record(z.string(), z.any()).optional(),
});
export const batchMetricIngestSchema = z
    .array(metricIngestSchema)
    .refine((val) => Array.isArray(val) && val.length > 0, {
    message: 'Invalid metric event',
});
export const metricAggregateSchema = z.object({
    firm_id: z.string(),
    namespace: MetricNamespaceEnum,
    metric_name: MetricNameEnum,
    start_date: z.string().refine((val) => /^\d{4}-\d{2}-\d{2}$/.test(val), {
        message: 'Invalid start_date format',
    }),
    end_date: z.string().refine((val) => /^\d{4}-\d{2}-\d{2}$/.test(val), {
        message: 'Invalid end_date format',
    }),
    next_token: z.string().optional(),
});
