export function UnsubscribedPage() {
  return (
    <div className="flex items-start justify-center min-h-screen">
      <div className="max-w-sm rounded overflow-hidden shadow-lg">
        <div className="px-6 py-4">
          <div className="flex justify-center items-center">
            <img
              className="w-sm"
              src="/extractly_logo64.png"
              alt="Extractly+ Logo"
            />
          </div>
          <hr className="my-4 border-t border-gray-200" />
          <div className="font-bold text-xl text-center">
            You are currently not subscribed to Extractly+.
          </div>
          <p className="flex justify-center items-center py-4 text-center">
            Please contact your system admin to allow access.
          </p>
        </div>
      </div>
    </div>
  )
}
