import { capitalize } from 'lodash'
import { MouseEventHandler } from 'react'

type Props = {
  icon: JSX.Element
  groupName: string
  hidden: boolean
  tooltipPosition: string
  onClick: MouseEventHandler<HTMLButtonElement> | undefined
}
const FileUnorderedListOptionBtn = ({
  groupName,
  icon,
  hidden,
  tooltipPosition,
  onClick,
}: Props) => {
  return (
    <div
      className={`relative group/${groupName} group/rename ${
        hidden ? 'hidden' : 'flex'
      } group-hover/ellipses:flex group-hover/ellipses:justify-center mr-2 hover:bg-gray-500 rounded`}
    >
      <button
        className="w-full h-full p-2.5 transition ease-in-out delay-100 hover:scale-110"
        onClick={onClick}
      >
        {icon}
      </button>
      <div
        className={`absolute ${tooltipPosition} scale-0 rounded bg-neutral-700 p-2 text-xs text-white  group-hover/${groupName}:scale-100 group-hover/${groupName}:w-max before:w-2 before:h-2 before:rotate-45 before:bg-neutral-700 before:absolute before:z-[-1]  before:left-0  before:right-0 before:mx-auto`}
      >
        {capitalize(groupName)}
      </div>
    </div>
  )
}

export default FileUnorderedListOptionBtn
