import { useContext } from 'react'
import FormModalContext from './FormModalContext'

export default function useFormModal() {
  const context = useContext(FormModalContext)
  if (context === undefined) {
    throw new Error(
      'useFormModalContext must be used within a FormModalProvider'
    )
  }

  return context!
}
