import { Group, Layer, Line } from 'react-konva'
import {
  EditTableFn,
  ExtractionMethod,
  PdfZoomInZoomOutEvent,
  RotationType,
  ShowSelectionOnlyState,
  TextBlock,
  TextcutRectangle,
  UserGeneratedLine,
} from '../../types'
import { KonvaEventObject } from 'konva/lib/Node'
import numeral from 'numeral'
import {
  calculateHeightandWidth,
  convertKonvaLineToPdfLine,
  convertToFourPointVertices,
  convetPdfLinesToKonvaLine,
  findTOpLeftPointFromVertices,
  rotateOcrBox,
} from '../../utils/spatial'
import RectGroup from '../RectGroup'
import { useEffect, useMemo, useState } from 'react'
import { Html } from 'react-konva-utils'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { nanoid } from 'nanoid'
import { useAppDispatch } from '../../dispatch'

type Props = {
  dataMatchRects: TextcutRectangle[]
  referenceRect: TextcutRectangle[]
  textcutRects: TextcutRectangle[]
  sumRects: TextcutRectangle[]
  showSelectionOnly: ShowSelectionOnlyState
  rotationDegree: RotationType
  fileId: string
  filePage: number
  ratioX: numeral.Numeral
  ratioY: numeral.Numeral
  editTable: EditTableFn
  wordsBlocks: TextBlock[]
  extractionMethod: ExtractionMethod | undefined
  dispatch: ReturnType<typeof useAppDispatch>
}

const DrawLayer = ({
  dataMatchRects,
  referenceRect,
  sumRects,
  textcutRects,
  showSelectionOnly,
  fileId,
  filePage,
  rotationDegree,
  ratioX,
  ratioY,
  editTable,
  wordsBlocks,
  extractionMethod,
  dispatch,
}: Props) => {
  const rects = useMemo(
    () => [...dataMatchRects, ...referenceRect, ...sumRects, ...textcutRects],
    [dataMatchRects, referenceRect, sumRects, textcutRects]
  )

  const [rulings, setRulings] = useState<{ [key: string]: null | JSX.Element }>(
    {}
  )

  const [horizontals, setHorizontals] = useState(() => {
    const obj: { [key: string]: 0 | 1 | 2 } = {}
    rects.forEach((rect) => (obj[rect.bindingId] = 0))
    return obj
  })

  useEffect(() => {
    setRulings((pre) => {
      rects.forEach((rect) => {
        if (!pre[rect.bindingId]) {
          pre[rect.bindingId] = null
        }
      })
      return { ...pre }
    })
    setHorizontals((pre) => {
      rects.forEach((rect) => {
        if (!pre[rect.bindingId]) {
          pre[rect.bindingId] = 0
        }
      })
      return { ...pre }
    })
  }, [rects])

  useEffect(() => {
    const handler = () => {
      setRulings({})
    }

    window.addEventListener(
      PdfZoomInZoomOutEvent.ZOOM_IN_ZOMM_OUT_EVENT,
      handler
    )
    return () =>
      window.removeEventListener(
        PdfZoomInZoomOutEvent.ZOOM_IN_ZOMM_OUT_EVENT,
        handler
      )
  }, [])

  const addLine = (bindingId: string, lines: number[]) => {
    if (lines.length !== 4) return
    const rect = rects.find((rect) => rect.bindingId === bindingId)
    if (!rect) return
    const transformed = convertKonvaLineToPdfLine([...lines], ratioX, ratioY)
    if (transformed.includes(null)) return
    rulings[bindingId] = null
    setRulings({ ...rulings })
    const line: UserGeneratedLine = {
      id: nanoid(),
      line: transformed as number[],
    }
    const destructed = { ...rect }
    if ('lines' in destructed && destructed.lines !== undefined) {
      const newLines = [...destructed.lines, line]
      destructed.lines = newLines
    } else {
      destructed.lines = []
      destructed.lines.push(line)
    }

    editTable(destructed, destructed.lines)
      .then((newId) => {
        if (typeof newId === 'string') {
          const pre = horizontals[bindingId]
          setHorizontals({ ...horizontals, [newId]: pre })
        }
      })
      .catch((err) => console.error(`addLine::${err}`))
  }

  const removeLine = (bindingId: string, lineId: string) => {
    const rect = rects.find((rect) => rect.bindingId === bindingId)
    if (!rect || !rect.lines) return
    const destructed = { ...rect }
    const newLines = [...destructed.lines!].filter((line) => line.id !== lineId)
    destructed.lines = newLines
    editTable(destructed, destructed.lines)
      .then((newId) => {
        if (typeof newId === 'string') {
          const pre = horizontals[bindingId]
          setHorizontals({ ...horizontals, [newId]: pre })
        }
      })
      .catch((err) => console.error(`removeLine::${err}`))
  }

  const changeRulingDirection = (bindingId: string, value: 0 | 1 | 2) => {
    if (bindingId in horizontals) {
      if (horizontals[bindingId] === value) return
      horizontals[bindingId] = value
      setHorizontals({ ...horizontals })
      rulings[bindingId] = null
      setRulings({ ...rulings })
    }
  }

  const draw = (arr: TextcutRectangle[]) =>
    arr
      .filter((rect) => rect.fileId === fileId && rect.filePage === filePage)
      .filter((rect) => {
        if (!showSelectionOnly.showSelectionOnly) return true
        if (
          rect.sheetId === showSelectionOnly.sheetId &&
          rect.rangeAddr === showSelectionOnly.rangeAddress
        ) {
          return true
        }
        return false
      })
      .map((rect) => {
        if (rect.degree !== rotationDegree.curr) {
          const targetDegree =
            (((rotationDegree.curr - rect.degree) % 360) + 360) % 360
          const input = convertToFourPointVertices(
            rect.x,
            rect.y,
            rect.w,
            rect.h
          )
          const arr = rotateOcrBox(input, targetDegree, rect.ocrW, rect.ocrH)
          const topLeft = findTOpLeftPointFromVertices(arr)
          const [hh, ww] = calculateHeightandWidth(
            arr[0],
            arr[1],
            arr[2],
            arr[3]
          )
          if (!topLeft) return rect
          rect = { ...rect, x: topLeft.x, y: topLeft.y, h: hh, w: ww }
          return rect
        } else {
          return rect
        }
      })
      .map((r) => (
        <RectGroup
          bindingId={r.bindingId}
          key={r.bindingId}
          fill={r.fill}
          stroke={r.stroke}
          x={numeral(r.x).divide(ratioX.value()).difference(2) ?? 0}
          y={numeral(r.y).divide(ratioY.value()).difference(2) ?? 0}
          w={numeral(r.w).divide(ratioX.value()).add(4).value() ?? 0}
          h={numeral(r.h).divide(ratioY.value()).add(4).value() ?? 0}
          ruling={rulings[r.bindingId]}
          changeRulingDirection={changeRulingDirection}
          rulingDirection={horizontals[r.bindingId]}
          lines={
            r.lines ? convetPdfLinesToKonvaLine(r.lines, ratioX, ratioY) : []
          }
          removeLine={removeLine}
          tag={r.tag}
          textCutRect={r}
          editTable={editTable}
          wordsBlocks={wordsBlocks}
          extractionMethod={extractionMethod}
          dispatch={dispatch}
        />
      ))

  // const mouseEnter = (e: KonvaEventObject<MouseEvent>) => {
  //   const name = e.target.name()
  //   const item = rects.find((rect) => rect.bindingId === name)
  //   if (item) {
  //     e.target.setAttr('fill', 'RGB(241,241,241, 0.5)')
  //   }
  // }

  // const mouseLeave = (e: KonvaEventObject<MouseEvent>) => {
  //   const name = e.target.name()
  //   const item = rects.find((rect) => rect.bindingId === name)
  //   if (item) {
  //     e.target.setAttr('fill', item.fill)
  //     if (item.bindingId in rulings) {
  //       rulings[item.bindingId] = null
  //       setRulings({ ...rulings })
  //     }
  //   }
  // }

  const mouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const name = e.target.name()
    const item = rects.find((rect) => rect.bindingId === name)
    const stage = e.target.getStage()
    const mousePos = stage?.getPointerPosition()
    const height = e.target.height()
    const width = e.target.width()
    const pos = e.target.getPosition()
    if (
      mousePos &&
      item &&
      item.bindingId in rulings &&
      item.bindingId in horizontals
    ) {
      if (!horizontals[item.bindingId]) return
      const { x: mouseX, y: mouseY } = mousePos
      const { x, y } = pos
      if (horizontals[item.bindingId] === 1) {
        rulings[item.bindingId] = (
          <Group name={item.bindingId}>
            <Line
              points={[x, mouseY, x + width, mouseY]}
              stroke={item.stroke}
              strokeWidth={2}
              dash={[10, 5]}
            />
            <Html
              divProps={{ style: { width: 16, height: 16 } }}
              transformFunc={(props) => {
                return {
                  ...props,
                  x: x + width - 14,
                  y: mouseY - 14,
                }
              }}
            >
              <AddOutlinedIcon
                className="bg-green-300 rounded-xl opacity-50 transition ease-in-out  hover:opacity-100 hover:scale-105"
                sx={{ fontSize: 14, border: 1, cursor: 'pointer' }}
                onClick={() =>
                  addLine(item.bindingId, [x, mouseY, x + width, mouseY])
                }
              />
            </Html>
          </Group>
        )
      } else if (horizontals[item.bindingId] === 2) {
        rulings[item.bindingId] = (
          <Group name={item.bindingId}>
            <Line
              points={[mouseX, y, mouseX, y + height]}
              stroke={item.stroke}
              strokeWidth={2}
              dash={[10, 5]}
            />
            <Html
              divProps={{ style: { width: 16, height: 16 } }}
              transformFunc={(props) => {
                return {
                  ...props,
                  x: mouseX - 7,
                  y: y - 6,
                }
              }}
            >
              <AddOutlinedIcon
                className="bg-green-300 rounded-xl opacity-50 transition ease-in-out  hover:opacity-100 hover:scale-105"
                sx={{ fontSize: 14, border: 1, cursor: 'pointer' }}
                onClick={() =>
                  addLine(item.bindingId, [mouseX, y, mouseX, y + height])
                }
              />
            </Html>
          </Group>
        )
      }
      setRulings({ ...rulings })
    }
  }

  const onTextcutRectClick = async (e: KonvaEventObject<MouseEvent>) => {
    let rect: TextcutRectangle | undefined
    const targetName = e.target.name()
    rect = rects.find((rect) => rect.bindingId === targetName)

    if (!rect) {
      const parent = e.target.getParent()
      if (!parent) return
      const name = parent.name()
      rect = rects.find((rect) => rect.bindingId === name)
    }

    Excel.run(async (ctx) => {
      if (!rect) return
      const sheet = ctx.workbook.worksheets.getItem(rect.sheetId)
      sheet.load('isNullObject')
      await ctx.sync()
      if (sheet.isNullObject) return
      sheet.getRange(rect.rangeAddr).select()
      sheet.activate()
    }).catch((err) => console.log(err))
  }

  return (
    <Layer onMouseMove={mouseMove} onClick={onTextcutRectClick}>
      {dataMatchRects.length > 0 && draw(dataMatchRects)}

      {referenceRect.length > 0 && draw(referenceRect)}
      {textcutRects.length > 0 && draw(textcutRects)}

      {sumRects.length > 0 && draw(sumRects)}
    </Layer>
  )
}

export default DrawLayer
