import { nanoid } from 'nanoid'
import {
  ExtractionMethod,
  TextcutRectangle,
  UserGeneratedLine,
} from '../../types'
import { isVertical, sortByReadingOrder } from '../../utils/spatial'
import { CustomWord, TableOptionsModalResponseData } from './types'
import {
  getCoordsIntersectionArea,
  getRowLinesFromWords,
  mergeTableRows,
  TableCell,
} from './utils'
import { cloneDeep } from 'lodash'

const useTableOptionsProcess = () => {
  const updateTextcutRect = (
    textCutRect: TextcutRectangle,
    tableOptions: TableOptionsModalResponseData,
    words: CustomWord[],
    extractionMethod: ExtractionMethod | undefined
  ): TextcutRectangle => {
    const hasHeader = tableOptions['hasHeader'] === 'on'
    const groupByColId = tableOptions['groupByCol[id]']
      ? Number(tableOptions['groupByCol[id]'])
      : -1
    const colTextAlignment = tableOptions['colTextAlignment[id]'] || 'top'

    const dataLines: UserGeneratedLine[] = textCutRect.lines || []

    const sortedWords = sortByReadingOrder(
      cloneDeep(words),
      (word) => word.coords
    )

    const tableTop = textCutRect.y
    const tableBottom = textCutRect.y + textCutRect.h
    const tableLeft = textCutRect.x
    const tableRight = textCutRect.x + textCutRect.w

    /**
     * Get the y data lines
     */
    const yDataLines = (dataLines || [])
      .map((line) => line.line)
      .filter((line) => !isVertical(line))
      .map(([, y1]) => y1)
      .sort((a, b) => a - b)

    /**
     * Get the x line from the lines
     */
    const xLines = (dataLines || [])
      .map((line) => line.line)
      .filter((line) => isVertical(line))
      .map(([x1]) => x1)
      .sort((a, b) => a - b)
    xLines.unshift(tableLeft)

    /**
     * Get the y lines from the words
     */
    let yLines = getRowLinesFromWords(sortedWords, extractionMethod)
    yLines.unshift(tableTop)
    // replace the last line with the bottom of the textCutRect
    yLines[yLines.length - 1] = tableBottom

    /**
     * Remove the header line if it exists
     */
    if (hasHeader) {
      const headerBottom = yDataLines[0]
      yLines = yLines.filter((y) => y > headerBottom)
    }

    /**
     * Construct the data array that contains the words in each cell
     */
    const yLen = yLines.length
    const xLen = xLines.length

    const data: TableCell[][] = []

    for (let y = 0; y < yLen - 1; y++) {
      const row: TableCell[] = []
      for (let x = 0; x < xLen; x++) {
        const x1c = xLines[x]
        const y1c = yLines[y]
        const x2c = xLines[x + 1] || tableRight
        const y2c = yLines[y + 1] || tableBottom

        const intersectedWords: CustomWord[] = []
        sortedWords.forEach((word) => {
          const [x1w, y1w, x2w, y2w] = word.coords
          const area = getCoordsIntersectionArea(
            [x1w, y1w, x2w, y2w],
            [x1c, y1c, x2c, y2c]
          )
          if (area > 0.5) {
            intersectedWords.push(word)
          }
        })

        row.push({
          row: y,
          col: x,
          words: intersectedWords,
          coords: [x1c, y1c, x2c, y2c],
        })
      }
      data.push(row)
    }

    /**
     * Combine the rows based on the groupByColId and colTextAlignment
     * 1. If colTextAlignment is top, then the groupByColCell is the start of the row
     * 2. If colTextAlignment is bottom, then the groupByColCell is the end of the row
     * 3. middle is not supported yet
     */
    let newData: TableCell[][] = []
    if (groupByColId !== -1) {
      let currentRow: TableCell[] = []

      data.forEach((row, idx) => {
        const word = row[groupByColId].words
          .map((word) => word.text)
          .join('')
          .trim()
        if (colTextAlignment === 'top') {
          if (idx === 0) {
            currentRow = row
          } else if (idx > 0 && word.length > 0) {
            newData.push(currentRow)
            currentRow = row
          } else {
            currentRow = currentRow.length
              ? mergeTableRows([currentRow, row])
              : row
          }
        } else if (colTextAlignment === 'bottom') {
          if (word.length > 0) {
            currentRow = currentRow.length
              ? mergeTableRows([currentRow, row])
              : row
          } else if (currentRow.length > 0) {
            newData.push(currentRow)
            currentRow = row
          }
        }
      })
      if (currentRow.length > 0) {
        newData.push(currentRow)
      }
    } else {
      newData = data
    }


    /**
     * Add the header row if it exists
     */
    if (hasHeader) {
      const headerBottom = yDataLines[0]

      const row: TableCell[] = []
      for (let c = 0; c < data[0].length; c++) {
        const x1c = xLines[c]
        const y1c = tableTop
        const x2c = xLines[c + 1] || tableRight
        const y2c = headerBottom

        const intersectedWords: CustomWord[] = []
        sortedWords.forEach((word) => {
          const [x1w, y1w, x2w, y2w] = word.coords
          const area = getCoordsIntersectionArea(
            [x1w, y1w, x2w, y2w],
            [x1c, y1c, x2c, y2c]
          )
          if (area > 0.5) {
            intersectedWords.push(word)
          }
        })

        row.push({
          row: 0,
          col: c,
          words: intersectedWords,
          coords: [x1c, y1c, x2c, y2c],
        })
      }
      newData.unshift(row)
    }

    const newLines: UserGeneratedLine[] = []
    const newValues: any[][] = []
    /**
     * Construct the horizontal lines
     */
    newData
      .filter((r) => r.length)
      .forEach((row, i) => {
        if (i < newData.length - 1) {
          const y2 = row[0].coords[3]
          const line: UserGeneratedLine = {
            id: nanoid(),
            line: [tableLeft, y2, tableRight, y2],
          }
          newLines.push(line)
        }
      })

    /**
     * Construct the vertical lines
     */
    if (newData.length) {
      newData[0].forEach((col, i) => {
        const x2 = col.coords[2]
        if (i < newData[0].length - 1) {
          const line: UserGeneratedLine = {
            id: nanoid(),
            line: [x2, tableTop, x2, tableBottom],
          }
          newLines.push(line)
        }
      })
    }

    /**
     * Construct the values array from the newData
     */
    newData.forEach((row, i) => {
      const value = row.map((cell) =>
        cell.words.map((word) => word.text).join(' ')
      )

      newValues.push(value)
    })

    const newTextCutRect: TextcutRectangle = {
      ...textCutRect,
      lines: newLines,
      values: newValues,
      tableOptions: {
        hasHeader,
        groupByColId: groupByColId.toString(),
        colTextAlignment,
      },
    }

    return newTextCutRect
  }

  return {
    updateTextcutRect,
  }
}

export default useTableOptionsProcess
