import React, { forwardRef } from 'react'

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  labelClassName?: string
  defaultChecked?: boolean
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, labelClassName, defaultChecked, ...props }, ref) => {
    return (
      <div className="flex items-center">
        <input
          type="checkbox"
          ref={ref}
          defaultChecked={defaultChecked}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          {...props}
        />
        {label && <label className={`ml-2 ${labelClassName}`}>{label}</label>}
      </div>
    )
  }
)

export default Checkbox
