import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'

type Props = {
  bindingId: string
  lineId: string
  removeLine: (id: string, lineId: string) => void
}

const RemoveLineIconButton = ({ bindingId, lineId, removeLine }: Props) => {
  return (
    <RemoveOutlinedIcon
      className="bg-red-200/50 rounded-xl opacity-50 transition ease-in-out  hover:opacity-100 hover:scale-105"
      sx={{
        fontSize: 14,
        border: 1,
        cursor: 'pointer',
      }}
      onClick={() => removeLine(bindingId, lineId)}
    />
  )
}

export default RemoveLineIconButton
