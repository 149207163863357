import { Dispatch } from 'react'
import { TextcutRectangle } from '../../types'
import { RadioGroup } from '@headlessui/react'

type Props = {
  rects: TextcutRectangle[]
  selected: string
  setSelected: Dispatch<React.SetStateAction<string>>
}

const DuplicateRadioGroup = ({ rects, selected, setSelected }: Props) => {
  return (
    <div className="w-full">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-y-2">
            {rects.map((rect) => (
              <RadioGroup.Option
                key={rect.bindingId}
                value={rect.bindingId}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-teal-300'
                      : ''
                  }
                ${checked ? 'bg-teal-900/75 text-white' : 'bg-white'}
                  relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            {rect.fileName}
                          </RadioGroup.Label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}

export default DuplicateRadioGroup
