import { MouseEventHandler } from 'react'

export type CustomContextualMenuProps = {
  x: number
  y: number
  menuItems: { onClick: MouseEventHandler<HTMLLIElement>; elem: JSX.Element }[]
}

export const CustomContextualMenu = ({
  x,
  y,

  menuItems,
}: CustomContextualMenuProps) => {
  return (
    <div
      style={{
        top: y,
        left: x,
      }}
      className={`absolute  bg-white divide-y divide-gray-100 rounded-lg shadow w-fit h-fit`}
    >
      <ul>
        {menuItems.map((item, i) => (
          <li
            className="px-2 py-2 rounded-lg font-semibold hover:bg-gray-100 w-full"
            onClick={item.onClick}
            key={i}
          >
            {item.elem}
          </li>
        ))}
      </ul>
    </div>
  )
}
