import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UndoEvent } from '../types'
import { RootState } from '../store'

type UndoSlice = {
  undoStack: UndoEvent[]
  redoStack: UndoEvent[]
}

const initialState: UndoSlice = {
  undoStack: [],
  redoStack: [],
}

const undoSlice = createSlice({
  initialState,
  name: 'UndoSlice',
  reducers: {
    pushToUndoStack: (state, action: PayloadAction<UndoEvent>) => {
      state.undoStack.push(action.payload)
    },
    popUndoStack: (state) => {
      state.undoStack.pop()
    },
    updateUndoStack: (state, action: PayloadAction<UndoEvent[]>) => {
      state.undoStack = action.payload
    },
    pushToRedoStack: (state, action: PayloadAction<UndoEvent>) => {
      state.redoStack.push(action.payload)
    },
    popRedoStack: (state) => {
      state.redoStack.pop()
    },
  },
})

export const {
  popUndoStack,
  pushToUndoStack,
  updateUndoStack,
  popRedoStack,
  pushToRedoStack,
} = undoSlice.actions

export const selectUndoStack = (root: RootState) => root.undo.undoStack
export const selectRedoStack = (root: RootState) => root.undo.redoStack

export default undoSlice.reducer
