import { Page as ReactPdfPage } from 'react-pdf'
import { useEffect, useRef, useState } from 'react'
import { ReaderPageProps } from './types'
import { EXTRA_HEIGHT } from './Reader'
import { TextBlock } from '../../types'
import { breakDownOcrByPage } from '../../utils/ocr'

const EXTRA_WIDTH = 5

const Page = ({
  virtualItem,
  viewports,
  scale = 1,
  rotation,
  pageObserver,
  shouldRender,
  classes,
  renderKonvaComponent,
  onLoadSuccess,
  onRenderSuccess,
  onLoadError,
  onRenderError,
  loading,
  ocr,
  rotationDegree,
}: ReaderPageProps) => {
  const pageRef = useRef<HTMLDivElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [pages, setPages] = useState<Map<string, TextBlock>>(new Map())
  const [tables, setTables] = useState<Map<string, TextBlock>>(new Map())
  const [cells, setCells] = useState<Map<string, TextBlock>>(new Map())
  const [lines, setLines] = useState<Map<string, TextBlock>>(new Map())
  const [words, setWords] = useState<Map<string, TextBlock>>(new Map())

  useEffect(() => {
    const [p, t, c, l, w] = breakDownOcrByPage(
      ocr,
      rotationDegree.curr,
      virtualItem.index
    )
    setPages(p)
    setTables(t)
    setCells(c)
    setLines(l)
    setWords(w)
  }, [ocr, rotationDegree, virtualItem.index])

  useEffect(() => {
    pageObserver && pageRef.current && pageObserver.observe(pageRef.current)
  }, [pageObserver])

  const innerBoxWidth =
    viewports && viewports[virtualItem.index]
      ? viewports[virtualItem.index].width + EXTRA_WIDTH
      : 0

  return (
    <div
      id={`page-${virtualItem.index + 1}`}
      ref={pageRef}
      data-index={virtualItem.index}
      style={{
        position: 'absolute',
        height: `${virtualItem.size}px`,
        transform: `translateY(${virtualItem.start}px)`,
        display: 'flex',
        justifyContent: 'center',
        minWidth: '100%',
      }}
      className={classes?.pageOuterBox}
    >
      <div
        style={{
          boxShadow: '2px 2px 8px 0 rgba(0, 0, 0, 0.1)',
          width: `${innerBoxWidth}px`,
          padding: '0px',
          margin: '0px',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          // height: `${shouldRender ? "fit-content" : `${virtualItem.size}px`}`,
          height: `${`${virtualItem.size - EXTRA_HEIGHT}px`}`,
          // minHeight: `${virtualItem.size - 100}px`,
        }}
        className={classes?.pageInnerBox}
      >
        {shouldRender && (
          <div
            style={{
              position: 'relative',
              height: 'fit-content',
              width: 'fit-content',
            }}
            className={classes?.pageWrapper}
          >
            <ReactPdfPage
              pageIndex={virtualItem.index}
              scale={scale}
              rotate={rotation}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              renderForms={false}
              loading={loading}
              onLoadSuccess={onLoadSuccess}
              onRenderSuccess={onRenderSuccess}
              onLoadError={onLoadError}
              onRenderError={onRenderError}
              canvasRef={canvasRef}
            >
              {renderKonvaComponent &&
                renderKonvaComponent({
                  pageIndex: virtualItem.index,
                  width: canvasRef.current?.clientWidth ?? 0,
                  height: canvasRef.current?.clientHeight ?? 0,
                  className: 'absolute inset-0 w-100 h-100',
                  allowToast: virtualItem.index === 0 ? true : false,
                  cells,
                  lines,
                  pages,
                  setCells,
                  setTables,
                  tables,
                  words,
                })}
            </ReactPdfPage>
          </div>
        )}
      </div>
    </div>
  )
}

export default Page
