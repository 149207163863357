import { RefObject, useEffect } from 'react'
import { normalizeWheelEventDirection } from './util'

const useWheel = (
  ref: RefObject<HTMLDivElement>,
  scale: number | undefined,
  setScale: (scale: number) => void
) => {
  useEffect(() => {
    const element = ref.current

    const handleWheel = (event: WheelEvent) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault()
        if (scale === undefined) return
        const delta = normalizeWheelEventDirection(event)
        const newScale = scale + delta / 1000
        setScale(newScale)
      }
    }

    if (element) {
      element.addEventListener('wheel', handleWheel, { passive: false })
      return () => {
        element.removeEventListener('wheel', handleWheel)
      }
    }
  }, [ref, scale, setScale])
}

export default useWheel
