import { Image } from 'react-konva'
import { First } from '../../types'
import useImage from 'use-image'

type RenderImageProps = {
  key: string
  x: number
  y: number
  image: First<ReturnType<typeof useImage>>
  onClick: (...args: any[]) => void
}

const RenderImage = ({ key, x, y, image, onClick }: RenderImageProps) => {
  return (
    <Image
      key={key}
      image={image}
      width={30}
      height={30}
      x={x}
      y={y}
      draggable
      onClick={() => onClick(key)}
    />
  )
}

export default RenderImage
